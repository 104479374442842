import httpClient from '@/_helpers/http-client';
import { authHeader } from "../_helpers/auth-header"

const esignconfirmation = async(data) => {
    var response = await httpClient.post('/agreement-esign', data, authHeader());
    return response;
}
const bioMetric = async(data) => {
    var response = await httpClient.post('/create-bioMetric', data, authHeader());
    return response
}

const esignStatus = async(paymentRefNo) => {
    const _body = {
        id: paymentRefNo
    }
    const response = await httpClient.post('/esign/status', _body, authHeader());
    return response;
}

const bioMetricStatus = async(paymentRefNo) => {
    const _body = {
        id: paymentRefNo
    }
    const response = await httpClient.post('/biometric/status', _body, authHeader());
    return response;
}

export const esignService = {
    esignconfirmation,
    bioMetric,
    esignStatus,
    bioMetricStatus
};