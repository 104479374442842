<template>
    <div class="container">
        <div class="header">
            <h1>Biometric Appointment Details</h1>
        </div>
        <div class="bioMetric">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="contDetails">Biometric Address Details</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label for="" class="label">Party Type</label>
                            <div class="form-group">
                                <select class="form-control" @change="getPartyType" v-model="partyType">
                                    <option disabled value="" selected>Select</option>
                                    <option v-for="(userType,inex) in userType " :key="inex">{{userType.userType}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label for="" class="label">Party Name</label>
                            <div class="form-group">
                                <select class="form-control" v-model="partyName" v-if="partyType == 'Landlord'" @change="getPartyDetails">
                                    <option disabled value="" selected>Select Party</option>
                                    <option v-for="(partyNameList,inex) in partyNameList" :key="inex">{{partyNameList.ownerName}}</option>
                                </select>
                                <select class="form-control" v-model="partyName" v-if="partyType == 'Tenant'" @change="getPartyDetails">
                                    <option disabled value="" selected>Select Party</option>
                                    <option v-for="(partyNameList,inex) in partyNameList" :key="inex">{{partyNameList.tenantName}}</option>
                                </select>
                                <select class="form-control" v-model="partyName" v-if="partyType == 'POA'" @change="getPartyDetails">
                                    <option disabled value="" selected>Select</option>
                                    <option v-for="(partyNameList,inex) in poaList" :key="inex">{{partyNameList.ownerPoaName}}</option>
                                </select>
                                <select class="form-control" v-model="partyName" v-if="partyType == 'Witness'" @change="getPartyDetails">
                                    <option disabled value="" selected>Select Party</option>
                                    <option v-for="(partyNameList,inex) in partyNameList" :key="inex">{{partyNameList.witnessName}}</option>
                                </select>
                                <select class="form-control" v-model="partyName" v-if="partyType == 'Other'" @change="getPartyDetails">
                                    <option disabled value="" selected>Select Party</option>
                                    <option v-for="(partyNameList,inex) in partyNameList" :key="inex">{{partyNameList.ownerName}}</option>
                                </select>
                                <select class="form-control" v-if="prtyNmeDemo == true">
                                    <option disabled selected>Select Party</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">Address Line 1</label>
                                <input type="text" name="addressLine1" class="form-control" placeholder="Address Line 1" v-model="addressLine1" required="required">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">Address Line 2</label>
                                <input type="text" class="form-control" name="addressLine2" placeholder="Address Line 2" v-model="addressLine2" required="required">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">City</label>
                                <input type="text" name="city" class="form-control" placeholder="City" v-model="city" required="required">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">Pin Code</label>
                                <input type="text" class="form-control" placeholder="6 Digit Pincode" name="pinCode" v-model="pinCode" required="required" pattern="^([0-9]{6})$">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="contDetails">Biometric Contact details</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">Contact Number</label>
                                <div class="phoneNo">
                                    <select class="form-control dropdown" name="conturyCode" v-model="conturyCode">
                                        <option  value="" selected>+91</option>
                                    </select>
                                    <input type="tel" class="form-control" placeholder="Mobile No." name="contactNumber" v-model="contactNumber" required="required" pattern="^[0-9]{10}$">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">E mail Address</label>
                                <input type="text" class="form-control" placeholder="Email Id" name="emailID" v-model="emailID" required="required" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">Preferred Date</label>
                                <!-- <datePicker
                                    input-class="form-control"
                                    ref="datecheck"
                                    id="prefrredDate"
                                    name="prefrredDate"
                                    placeholder="Preferred Date"
                                    v-model="prefrredDate"
                                ></datePicker> -->
                                <datePicker
                                    input-class="form-control"
                                    id="prefrredDate"
                                    ref="datecheck"
                                    name="prefrredDate"
                                    v-model="prefrredDate"
                                    v-validate="{ required: true }"
                                    :disabledDates="disabledDates"
                                    placeholder="Preferred Date"
                                    ></datePicker>
                                <!-- <input type="text" name="prefrredDate" class="form-control"  v-model="prefrredDate"> -->
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label for="" class="label">Preferred Time</label>
                                <dateTimePicker
                                    input-class="form-control"
                                    ref="datecheck"
                                    id="prefrredTime"
                                    name="prefrredTime"
                                    placeholder="Preferred Time"
                                    v-model="prefrredTime"
                                    v-validate="{ required: true }"
                                    format="h:i:s"
                                ></dateTimePicker>
                                <!-- <input type="text" class="form-control"  name="prefrredTime" v-model="prefrredTime"> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                            <button type="button" class="btn btn-primary" @click="finalSubmit"> Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <overlay :openModel="openModel"></overlay>
    </div>
</template>
<script>
import { mapGetters,mapActions,mapState } from "vuex";
import dateTimePicker from "./dateTimePicker/dateTimePicker";
import datePicker from 'vuejs-datepicker'
import overlay from './templates/overlay'

export default {
name:'bioMetric',
components: {
    dateTimePicker,datePicker,overlay
},
data(){
    return{
        userType:[
            {
                "userType":"Landlord"
            },
            {
                "userType":"Tenant"
            },
            {
                "userType":"Witness"
            },
            {
                "userType":"Other"
            } 
        ],
        prtyNmeDemo:true,
        partyType:'',
        partyName:'',
        partyNameList:[],
        poaList:[],
        dataAll:"",
        addressLine1:'',
        addressLine2:'',
        city:'',
        pinCode:'',
        conturyCode:'',
        contactNumber:'',
        emailID:'',
        prefrredDate:'',
        prefrredTime:'',
        flatNumber:"",
        disabledDates: {
          to: new Date(Date.now())
        },
        openModel:""
    }
},
computed: {
    ...mapGetters("master", { masterData: "getData" }),
    ...mapState({state:state=>state.agreement}),
}, 
methods:{
    ...mapActions('agreement',["previewAgreementInfo",]),
    ...mapActions('esignBiometric', {
            bioMetricStatus: 'bioMetricStatus',bioMetric:"bioMetric"
        }),
    async getPreviewData(){
        let data = await this.previewAgreementInfo({"id":this.$route.params.id});
        let poa = false
        this.dataAll = data.data;
        for (let i = 0; i < this.dataAll.landlord.length; i++) {
            let checkPOA = this.dataAll.landlord[i]['ownerPOA'];
            if(checkPOA.length>0  && !poa){
                this.userType.push({"userType":"POA"});
                poa = true;
            }
        }
    },
    getPartyType(){
        this.prtyNmeDemo = false;
        let partyType = this.partyType;
            this.partyNameList = this.dataAll[partyType.toLowerCase()];
            if(partyType == 'POA'){
                for (let i = 0; i < this.dataAll.landlord.length; i++) {
                    let ownerPOA = this.dataAll.landlord[i]['ownerPOA']
                    this.poaList.push(ownerPOA[0]);
                }
            }else if(partyType == 'Other'){
                 this.houseNumber = '',
                this.streetAddress = '',
                this.city = '',
                this.pinCode = '',
                this.contactNumber = '',
                this.emailID = ''
            }
    },
    getPartyDetails(){
        let add = this.dataAll[this.partyType.toLowerCase()];
        if(this.partyType == 'Landlord'){
            for (let i = 0; i < add.length; i++) {
                let addnew = add[i];
                if(addnew.ownerName == this.partyName){
                    this.addressLine1 = addnew.ownerAddress1,
                    this.addressLine2 = addnew.ownerAddress2,
                    this.city = addnew.ownerCity,
                    this.pinCode = addnew.ownerPin,
                    this.contactNumber = addnew.ownerMobile,
                    this.emailID = addnew.ownerEmail

                }
            }
        }else if(this.partyType == 'Tenant'){
            for (let i = 0; i < add.length; i++) {
                let addnew = add[i];
                if(addnew.tenantName == this.partyName){
                    this.addressLine1 = addnew.tenantAddress1,
                    this.addressLine2 = addnew.tenantAddress2,
                    this.city = addnew.tenantCity,
                    this.pinCode = addnew.tenantPin,
                    this.contactNumber = addnew.tenantMobile,
                    this.emailID = addnew.tenantEmail

                }
            }
        }else if(this.partyType == 'Witness'){
            for (let i = 0; i < add.length; i++) {
                let addnew = add[i];
                if(addnew.witnessName == this.partyName){
                    this.addressLine1 = addnew.witnessAddress1,
                    this.addressLine2 = addnew.witnessAddress2,
                    this.city = addnew.witnessCity,
                    this.pinCode = addnew.witnessPin,
                    this.contactNumber = addnew.witnessPhone,
                    this.emailID = addnew.witnessEmail

                }
            }
        }else if(this.partyType == 'POA'){
            for (let i = 0; i < this.dataAll.landlord.length; i++) {
                let ownerPOA = this.dataAll.landlord[i]['ownerPOA']
                if(ownerPOA[0].ownerPoaName == this.partyName){
                    this.addressLine1 = ownerPOA[0].ownerPoaAddress1,
                    this.addressLine2 = ownerPOA[0].ownerPoaAddress2,
                    this.city = ownerPOA[0].ownerPoaCity,
                    this.pinCode = ownerPOA[0].ownerPoaPin,
                    this.contactNumber = ownerPOA[0].ownerPoaMobile,
                    this.emailID = ownerPOA[0].ownerPoaEmail
                }
            }
        }                
    },
    finalSubmit(){
        this.openModel = "loader"
        const data = {
            "transactionId":this.$route.params.id,
            "partyType":this.partyType,
            "partyName":this.partyName,
            "countryCode":"+91",
            "number":this.contactNumber,
            "email":this.emailID,
            "addressLine1":this.addressLine1,
            "addressLine2":this.addressLine2,
            "city":this.city,
            "pinCode":this.pinCode,
            "preferredDate":this.prefrredDate,
            "preferredTime":this.prefrredTime
        }
        this.bioMetric(data);
        this.openModel = ""
    }

 },
    
 beforeMount(){
    this.bioMetricStatus(this.$route.params.id); 
    this.getPreviewData();
 },
}
</script>