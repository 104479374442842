import { getData } from "@/_services/master.service"
import { getItemFromStorage, setItemInStorage } from "@/_helpers/utility"

const keyName = "masterData";
const state = {
    data: {}
}

const getters = {
    getData(state) {
        if (state.data && state.data.length > 0)
            return state.data;
        else
            return getItemFromStorage(keyName);
    },

}

const actions = {
    async fetchData({ commit }) {
        try {
            const response = await getData('Agreement');
            commit('SetData', response.data);
        } catch (er) {
            console.error(er);
            commit('SetData', {});
        }
    }
}


const mutations = {
    SetData(state, data) {
        state.data = data;
        setItemInStorage(keyName, data);
    }
}

export const master = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}