import Vue from 'vue';
import Router from 'vue-router';

import { userLogin } from '@/_services/user.service'
import { setItemInStorage } from './utility'

import verificationPage from '@/views/Verification'
import AgreementSelect from '../components/agreements/agreementSelect'
import bioMetric from '../components/agreements/bioMetric'
import agreementView from '../components/agreements/agreementPrivew'
import rentalAgreement from '../components/agreements/RentalTemplate'
import eSignBiometric from '../components/agreements/esignBiometric'
import noAuthorization from '../components/agreements/noAuthorization'
import myAgreement from '../components/agreements/myAgreement'
import agreementById from '../components/agreements/agreementById'
import showDocument from '../components/agreements/showDocument'
import editAgreementParties from '../components/editAgreementParties'
import BeatsAgreementList from '../components/BeatsAgreementList'
import pdfDownload from '../components/agreements/pdfDownload'

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [{ path: '/rentalagreement/:source/:token/:id?', component: rentalAgreement },
        { path: '/agreementView/:source/:token/:id', component: agreementView },
        { path: '/pdfDownload/:id/:stamp', component: pdfDownload },
        { path: '/howItWork', component: AgreementSelect },
        { path: '/bioMetric/:id', component: bioMetric },
        { path: '/eSign/:source/:token/:id', component: eSignBiometric },
        { path: '/verification/:type?', component: verificationPage },
        { path: '/no-authorisation', component: noAuthorization },
        { path: '/my-Agreement/:source/:token', component: myAgreement },
        { path: '/document/:name', component: showDocument },
        { path: '/agreementById/:source/:token/:type/:id/:cityType?', component: agreementById },
        { path: '/editAgreementParties/:source/:token/:type/:id', component: editAgreementParties },
        { path: '/beatsAgreementList', component: BeatsAgreementList },
        // otherwise redirect to home
        { path: '*', component: noAuthorization }
    ]
});


router.beforeEach(async(to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    if (to.matched[0].path == "/rentalagreement/:source/:token/:id?" ||
        to.matched[0].path == "/my-Agreement/:source/:token" ||
        to.matched[0].path == "/agreementView/:source/:token/:id" ||
        to.matched[0].path == '/editAgreementParties/:source/:token/:type/:id' ||
        to.matched[0].path == '/agreementById/:source/:token/:type/:id/:cityType?' ||
        to.matched[0].path == "/eSign/:source/:token/:id") {
        const token = sessionStorage.getItem('token');
        if (token && token == to.params.token) {
            next();
        } else if (to.params.token) {
            const data = await userLogin({ "token": to.params.token, "source": to.params.source });
            if (data.data.status) {
                setItemInStorage('token', data.data.data.token);
                setItemInStorage('user', data.data.data.user);
                setItemInStorage('source', to.params.source);
                setItemInStorage('authToken', data.data.data.authToken);
                next();
            } else {
                return next('/no-authorisation');
            }

        } else {
            return next('/no-authorisation');
        }
    } else if (to.path == "/no-authorisation" ||
        to.matched[0].path == '/beatsAgreementList' ||
        to.matched[0].path == '/document/:name' || to.matched[0].path == '/pdfDownload/:id/:stamp'
    ) {
        next();
    } else {
        const token = sessionStorage.getItem('token');
        if (token) {
            next();
        } else {
            return next('/no-authorisation');
        }
    }
})