import httpClient from '@/_helpers/http-client';
import { authHeader } from "../_helpers/auth-header"

const checkCoupon = async(data) => {
    var response = await httpClient.post('/check-coupon-validity', data, authHeader());
    return response;
}
const getCoupons = async (data) => {
    var response = await httpClient.post('/get-coupons', data, authHeader());
    return response;
}

const checkCPlusDiscount = async (data) => {
    var response = await httpClient.post('/check-cpdiscount', data, authHeader());
    return response;
}

const submitSubAgreement = async (data) => {
    var response = await httpClient.post('/subscription-agreement', data, authHeader());
    return response;
}

export {
    checkCoupon,
    getCoupons,
    checkCPlusDiscount,
    submitSubAgreement
};