<template>
 <div>
    <div v-if="walkInBio">
            <h4 class="bioHeading">WalkIn Biometric</h4>
            <div class="BioCard">
                <div class="bioList">
                    <!-- <div class="bioHead"><span>{{walkInBio.address}}</span></div> -->
                    <div class="bioBody">
                        <p class="bioNumber walkinBio"><span class="bioName">Address:  </span><span class="editBio" @click="openNewBio(indx)"><em class="icon-edit"></em> edit</span></p>
                        <p class="bioNumberSub">{{walkInBio.address}}, {{walkInBio.pinCode}}</p>
                        <!-- <p class="bioNumber">Pincode:</p>
                        <p class="bioNumberSub"></p> -->
                    </div>
                </div>
            </div>
    </div>
    <div v-if="bioMatericData">
        <h4 class="bioHeading">Doorstep Biometric</h4>
        <div class="sumBody">
            <div class="BioCard">
                <div class="bioList" v-for="(boxes,indx) in bioMatericData" :key="indx+'b'">
                    <div class="bioHead"><span class="bioName">{{boxes.name}}</span> <span class="editBio" @click="openNewBio(indx)"><em class="icon-edit"></em> edit</span></div>
                    <div class="bioBody">
                        <p class="bioNumber">Phone Number:</p>
                        <p class="bioNumberSub">{{boxes.phoneNo}}</p>
                        <p class="bioNumber">Address:</p>
                        <p class="bioNumberSub">{{boxes.address}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:["bioMatericData","walkInBio"],
    methods:{
        openNewBio(index){
             this.$emit('openBiometric',index)
        }
    }
}
</script>