<template>
    <div class="">
      <div class="packageBanner Newpackage">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-7 col-sm-12">
                        <div class="packageList">
                            <h3 class="ora-he">Online Rental Agreement</h3>
                            <p class="ora-he-sub">Best online agreement services in Bangalore</p>
                            <ul>
                                <li>
                                    <figure><span><img src="../../assets/images/doorstep.svg" class="img-responsive"></span></figure>
                                    <div class="doorstep">
                                        <h4>Doorstep Delivery</h4>
                                        <p>Zero contact document delivery. No need to step out of home.</p>
                                    </div>
                                </li>
                                <li>
                                    <figure><span><img src="../../assets/images/superQuick.svg" class="img-responsive"></span></figure>
                                    <div class="doorstep">
                                        <h4>Super Quick & Easy</h4>
                                        <p>Draft your agreement in minutes with complete customisation.</p>
                                    </div>
                                </li>
                                <li>
                                    <figure><span><img src="../../assets/images/dedicated.svg" class="img-responsive"></span></figure>
                                    <div class="doorstep">
                                        <h4>Dedicated Relationship Manger</h4>
                                        <p>Your single point of contact for any assistance you require.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12  userPkageRight">
                        <div class="whBox rightBox">
                            <h6 class="pkh">Please Login/Signup to process further</h6>
                            <div class="form-group phoneNo">
                                <select name="" id="" class="form-control dropdown">
                                    <option value="">+91</option>
                                    <option value="">+92</option>
                                    <option value="">+93</option>
                                    <option value="">+94</option>
                                    <option value="">+95</option>
                                </select>
                                <input type="text" class="form-control" placeholder="Enter Phone No.">
                            </div>
                            <div class="btnBox">
                              <router-link to="/" style="text-decoration:none">  <button class="btn btn-primary btn-block">Create Rental Agreement</button></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container hiwContainter">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h5 class="hiw">How it Works</h5>
                </div>	
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 hiwnewList">
                    <figure class="onlinwe"><em class="icon-fillDetails"></em></figure>
                    <p>Fill in the details online and<br>make the payment</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 hiwnewList">
                    <figure class="DigitallySing"><em class="icon-digitallysign"></em></figure>
                    <p>Digitally sign the agreement at your convenience</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 hiwnewList">
                    <figure class="Stamped"><em class="icon-get-EStamped"></em></figure>
                    <p>Get an e-stamped agreement In<br>your mail instantly</p>
                </div>      
            </div>
        </div>
        <div class="noBropro">
            <div class="header">
                <figure> <img src="../../assets/images/faq.png" alt="faq" class="img-responsive" /> </figure>
                <h6>Frequenly asked questions</h6>
            </div>
            <div class="faqBox">
                <div class="missCal"><em class="icon-call"></em> Give a missed call to 1800 208 3344 or WhatsApp 1800 208 3344 to get help with your property listing</div>
                <div class="panel-group" id="accordion">
                <div class="panel panel-default">
                    <div class="panel-heading" v-on:click="togglenow(1)">
                    <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapse1">Is the rent agreement generated online valid?</h4>
                        <em class="icon-add active"></em>
                        <em class="icon-minus"></em>
                    </div>
                    <div id="collapse1" class="panel-collapse collapse in" v-show="acordian == 1">
                    <div class="panel-body">Online rent agreement which has been executed on stamp paper &amp; has been signed by both the parties is legally valid and can be used as address proof.</div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading" v-on:click="togglenow(2)">
                    <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapse2">Do I need to visit any government office?</h4>
                        <em class="icon-add active"></em>
                        <em class="icon-minus"></em>
                    </div>
                    <div id="collapse2" class="panel-collapse collapse" v-show="acordian == 2">
                    <div class="panel-body">No, We will conduct the whole process for you online or at your home. We will save you from the hassles of going to government offices</div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading" v-on:click="togglenow(3)">
                    <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapse3">Can I add the clauses mentioned in the online rent agreement?</h4>
                        <em class="icon-add active"></em>
                        <em class="icon-minus"></em>
                    </div>
                    <div id="collapse3" class="panel-collapse collapse" v-show="acordian == 3">
                    <div class="panel-body">Along with the set of clauses that are already in the rent agreement, Some customised clauses can be added during the rent agreement creation flow.</div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading" v-on:click="togglenow(4)">
                    <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapse4">What are the documents required to make the online rent agreement?</h4>
                        <em class="icon-add active"></em>
                        <em class="icon-minus"></em>
                    </div>
                    <div id="collapse4" class="panel-collapse collapse" v-show="acordian == 4">
                    <div class="panel-body">You don't need to have any document with you while creating the rent agreement online but during the Biometric verification all parties involved
                        (Tenants/Landlords/Witnesses) must have:
                        1.Passport-size photographs
                        2.Photocopy of identity proof (PAN card, Aadhar card, election card, passport, etc.)
                        3.Original identity proof for verification</div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading" v-on:click="togglenow(5)">
                    <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapse5">How is the rent agreement delivered to me?</h4>
                        <em class="icon-add active"></em>
                        <em class="icon-minus"></em>
                    </div>
                    <div id="collapse5" class="panel-collapse collapse" v-show="acordian == 5">
                    <div class="panel-body">Yes, a hard copy of the agreement will be delivered to your address after all the registration process is completed</div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading" v-on:click="togglenow(6)">
                    <h4 class="panel-title" data-toggle="collapse" data-parent="#accordion" href="#collapse6">How much does it cost for creating the online rent agreement?</h4>
                        <em class="icon-add active"></em>
                        <em class="icon-minus"></em>
                    </div>
                    <div id="collapse6" class="panel-collapse collapse" v-show="acordian == 6">
                    <div class="panel-body">The cost of creating a rent agreement depends on the duration of the rent agreement, monthly rent, refundable security deposit, etc. You will get to know the exact cost while creating the ren agreement</div>
                    </div>
                </div>
                </div>
            </div>
            <!-- <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-7 mx-auto">
                        <h5 class="hiw">SquareYards Promise</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    name:'selectAgreement',
    data(){
        return{
            acordian:1,
        }
    },
    methods:{
        togglenow(data){
           this.acordian = data;
       },
    },
    mounted(){
        document.body.classList.add('pacakgeBackground')
    }
}
</script>