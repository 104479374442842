import httpClient from '@/_helpers/http-client';
import { authHeader } from "../_helpers/auth-header"

const getVendors = async() => {
    var response = await httpClient.get('/biometric-vendors')
    return response;
}

const assignVendor = async(data) => {
    var response = await httpClient.post('/assign-vendor', data, authHeader())
    return response;
}
export {
    getVendors,
    assignVendor
}