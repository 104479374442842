import { biometricLocalities, bioMetricAddress, bioMetricDetails, partiesBiometric } from "@/_services/bioMetric.service";
const actions = {
    async getVendorsLocality({ dispatch }, data) {
        try {
            // console.log(data)
            var response = await biometricLocalities(data);
            if (response.data && response.data["status"]) {
                return { "status": true, "data": response.data["localities"], "biometricLocalities": response.data["biometricLocalities"] }
            } else {
                dispatch('alert/error', response.data["message"], { root: true })
                return { "status": false }
            }
        } catch (er) {
            // console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', message, { root: true });
        }
    },
    async updateBioMetric({ dispatch }, data) {
        try {
            // console.log(data)
            var response = await bioMetricAddress(data);
            if (response.data && response.data["status"]) {
                return { "status": true, "data": response.data["localities"] }
            } else {
                dispatch('alert/error', response.data["message"], { root: true })
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async getBioMetricAddress({ dispatch }, data) {
        try {
            // console.log(data)
            var response = await bioMetricDetails(data);
            if (response.data && response.data["status"]) {
                return { "status": true, "data": response.data["addresses"], "bioMetricType": response.data["vendorLocation"] }
            } else {
                dispatch('alert/error', response.data["message"], { root: true })
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async sendBioMetricAddress({ dispatch }, data) {
        try {
            // console.log(data)
            var response = await partiesBiometric(data);
            if (response.data && response.data["status"]) {
                return { "status": true, "data": response.data["status"] }
            } else {
                dispatch('alert/error', response.data["message"], { root: true })
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },

}
export const bioMetric = {
    namespaced: true,
    actions,
}