<template>
    <div class="clipBoard">
            <div class="heading demo">
              <figure>
                <img
                  src="../../../assets/images/aggrement-clip.png"
                  alt="clip"
                  class="img-responsive"
                />
              </figure>
              <div class="skeleton-box skeletonHeading"></div>
              <div class="skeleton-box skeletonSub"></div>
            </div>
            <div class="clipBox box-shadow">
                <div class="skeleton-box skeletonCenter"></div>
                <div class="skeleton-box skeletontext"></div>
                <div class="skeleton-box skeletontext"></div>
                <div class="skeleton-box skeletontext"></div>
                <div class="skeleton-box skeletontext half"></div>
            </div>
            
          </div>
</template>

<script>
export default {
  name:"Default"
}
</script>