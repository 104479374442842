<template>
    <div class="container-fluid">
        <div class="transaction">
            <div class="row">
                <div class="col-lg-12 col-sm-12 col-md-12">
                    <h2 class="pt-30 pb-15">E-sign Details</h2>
                </div>
                <div class="col-lg-12 col-sm-12 col-md-12">
                    <div class="table-responsive">
                        <table cellspacing="0" class="table  table-bordered e-sign">
                            <thead>
                                <tr>
                                    <th width="45">S.No.</th>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th>Number</th>
                                    <th>E-mail</th>
                                    <th><span v-if="agreementCity=='Mumbai' ||agreementCity=='Pune' ||agreementCity=='Thane'||agreementCity=='Navi Mumbai'">Biometric Status</span><span v-else>E-sign Status</span></th>
                                    <th v-if="esignInfoData.length>0">Esign-Link</th>
                                    <th  v-if="agreementStatus == 'Paid' || agreementStatus=='BioMetric Scheduled' || agreementStatus == 'Submitted'" class="action">Action</th>
                                </tr>
                            </thead>
                            <tbody v-for="(landlord,index) in reqData.landlord" :key="index">
                            <tr id="0" v-if="landlord.ownerPin!=''">
                                <td>{{index + 1}}</td>
                                <td><h3  v-show="landlordoffset!=index">{{landlord.ownerName}}</h3><input  v-show="landlordoffset==index" type="text" :id="'landlordName'+index" disabled="true"  class="form-control emailInput" v-model.trim="landlord.ownerName"></td>
                                <td>Owner</td>
                                <td>{{landlord.ownerMobile}} </td>
                                <td><h3 v-show="landlordoffset!=index">{{landlord.ownerEmail}}</h3><input  v-show="landlordoffset==index" type="text" :id="'landlordEmail'+index" disabled="true" @blur="isEmail" class="form-control emailInput" v-model="landlord.ownerEmail"></td>
                                <td v-if="agreementCity =='Mumbai' || agreementCity =='Pune' || agreementCity =='Thane' || agreementCity == 'Navi Mumbai'">
                                    <span v-if="agreementStatus=='Agreement Generated'">Done</span>
                                <span v-else>Pending</span> 
                                </td>
                                <td v-else>
                                <span v-if="esignPartyStatus(landlord.ownerName)">Done</span>
                                <span v-else>Pending</span>
                                </td>
                                <td  v-if="esignInfoData.length>0">
                                    <input type="text" style="display:none" class="form-control" :value="esignLink(landlord.ownerName)" :id="'landlordEsign'+index">
                                    <button class="btn esignLinkBtn" :id="'lbtn'+index" @click="copyLink('landlordEsign',index,'lbtn')"><span> Copy Link</span></button>
                                </td>
                                <td v-if="agreementStatus == 'Paid' || agreementStatus=='BioMetric Scheduled' || agreementStatus == 'Submitted'" width="150">
                                <div class="addNewBox" >
                                    <span v-show="landlordoffset!=index" class="edit" @click="landlordEditMode(['landlordName','landlordEmail'],index)" >
                                        <em class="icon-edit"></em>
                                    </span>
                                    <span v-show="landlordoffset==index" class="edit" @click="landlordsave(['landlordName','landlordEmail'],index)">
                                            <em class="icon-check"></em>
                                        </span>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-for="(tenant,index) in reqData.tenant" :key="index+'A'">
                                <tr v-if="tenant.tenantPin!=''">
                                    <td v-if="reqData.landlord.length >0">{{reqData.landlord.length + index + 1}}</td>
                                    <td v-else>{{index +1}}</td>
                                    <td><h3 v-show="tenantoffset!=index">{{tenant.tenantName}}</h3>  <input v-show="tenantoffset==index" type="text" :id="'tenantName'+index" disabled="true"  class="form-control emailInput" v-model="tenant.tenantName"></td>
                                    <td>Tenant</td>
                                    <td>{{tenant.tenantMobile}}</td>
                                    <td><h3 v-show="tenantoffset!=index">{{tenant.tenantEmail}}</h3><input v-show="tenantoffset==index" type="text" :id="'tenantEmail'+index" disabled="true" @blur="isEmail" class="form-control emailInput" v-model.trim="tenant.tenantEmail"></td>
                                    <td v-if="agreementCity =='Mumbai' || agreementCity =='Pune' || agreementCity =='Thane' || agreementCity == 'Navi Mumbai'">
                                    <span v-if="agreementStatus=='Agreement Generated'">Done</span>
                                    <span v-else>Pending</span> 
                                </td>
                                <td v-else>
                                <span v-if="esignPartyStatus(tenant.tenantName)">Done</span>
                                <span v-else>Pending</span>
                                </td>
                                <td  v-if="esignInfoData.length>0">
                                    <input type="text" style="display:none" class="form-control esgn" :value="esignLink(tenant.tenantName)" :id="'tenantEsign'+index">
                                    <button class="btn esignLinkBtn" :id="'tbtn'+index" @click="copyLink('tenantEsign',index,'tbtn')">Copy Link</button>
                                </td>
                                <td  v-if="agreementStatus == 'Paid' || agreementStatus=='BioMetric Scheduled'|| agreementStatus == 'Submitted' " width="150">
                                    <div class="addNewBox" >
                                        <span v-show="tenantoffset!=index" class="edit" @click="tenantEditMode(['tenantName','tenantEmail'],index)" >
                                            <em class="icon-edit"></em>
                                        </span>
                                        <span  v-show="tenantoffset==index" class="edit" @click="tenantsave(['tenantName','tenantEmail'],index)" >
                                            <em class="icon-check"></em>
                                        </span>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            <tbody  v-for="(witness,index) in reqData.witness" :key="index+'B'" >
                                <tr v-if="(agreementCity=='Mumbai' || agreementCity=='Pune'|| agreementCity =='Thane' || agreementCity == 'Navi Mumbai') && witness.witnessUID!='' ">
                                    <td v-if="reqData.landlord.length > 0 && reqData.tenant.length > 0">{{reqData.landlord.length + reqData.tenant.length +index + 1}}</td>
                                    <td v-else-if="reqData.tenant.length > 0">{{ reqData.tenant.length +index + 1}}</td>
                                    <td v-else>{{index +1}}</td>
                                    <td> <h3 v-show="witnessoffset!=index">{{witness.witnessName}}</h3> <input v-show="witnessoffset==index" type="text" :id="'witnessName'+index" disabled="true"  class="form-control emailInput" v-model="witness.witnessName"></td>
                                    <td>Witness</td>
                                    <td>{{witness.witnessPhone}}</td>
                                    <td><h3 v-show="witnessoffset!=index">{{witness.witnessEmail}}</h3> <input v-show="witnessoffset==index" type="text" :id="'witnessEmail'+index" @blur="isEmail" class="form-control emailInput" disabled="true" v-model="witness.witnessEmail"></td>
                                    <td v-if="agreementCity =='Mumbai' || agreementCity =='Pune'|| agreementCity =='Thane' || agreementCity == 'Navi Mumbai'">
                                    <span v-if="agreementStatus=='Agreement Generated'">Done</span>
                                <span v-else>Pending</span> 
                                </td>
                                <td v-else>
                                <span v-if="esignPartyStatus(witness.witnessEmail)">Done</span>
                                <span v-else>Pending</span>
                                </td>
                                    <td  v-if="agreementStatus == 'Paid' || agreementStatus=='BioMetric Scheduled' || agreementStatus == 'Submitted'" width="150">
                                    <div class="addNewBox" >
                                        <span v-show="witnessoffset!=index" class="edit" @click="witnessEditMode(['witnessName','witnessEmail'],index)">
                                            <em class="icon-edit"></em>
                                        </span>
                                        <span v-show="witnessoffset==index" class="edit" @click="witnessSave(['witnessName','witnessEmail'],index)">
                                            <em class="icon-check"></em>
                                        </span>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div  v-if="agreementStatus == 'Paid' || agreementStatus=='BioMetric Scheduled'|| agreementStatus == 'Submitted' " class="stepButton"><button type="button"  @click="submitChange" id="submitButton" class="next">Submit</button></div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";

export default {
    name:"editAgreementParties",
    data(){
        return {
            reqData:{},
            previewData:"",
            fileData:"",
            agreementCity:'',
            agreementName:'',
            biometricData:'',
            esignInfoData:'',
            agreementStatus:'',
            btnActiveLandlord:true,
            btnActiveTenant:true,
            btnActiveWitness:true,
            landlordoffset:-1,
            witnessoffset:-1,
            tenantoffset:-1,
        }
    },
     computed: {
         ...mapGetters("master", { masterData: "getData" }),
        getAgreementComponent:function(){
            return "LeaseAgreement";
        }
    },
    beforeCreate: function() {
        document.body.className = 'bg-white';
    },
    methods:{
        ...mapActions('agreement',["previewAgreementInfo"]),
        ...mapActions('agreement',["agreementInfo"]),
        ...mapActions('esignBiometric', {
            esignconfirmation: 'esignconfirmation',esignStatus:"esignStatus"
        }),
        isAlphabet(event){
            let char = String.fromCharCode(event.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char)){ return true;} // Match with regex 
            else {event.preventDefault();return false}
        },
        isEmail(event){
            // let char = String.fromCharCode(event.keyCode); // Get the character
            if(/([A-Z0-9a-z_-][^@])+?@[^$#<>?]+?\.[\w]{2,4}/.test(event.target.value)){ 
                return true;} // Match with regex 
            else {
                event.target.value="";
                return false}
        },

        async submitChange(){
           const data = {
                "type":this.$route.params.type,
                "id":this.$route.params.id,
                "agreement":this.reqData,
           }
        const response =  await this.agreementInfo(data);
        console.log(response)
        },
        copyLink(type,index,c){
            // console.log(type+index)
            let esignLinkBtn = document.getElementsByClassName("esignLinkBtn");
            for(var i=0;i<esignLinkBtn.length;i++){
                esignLinkBtn[i].innerHTML="Copy Link"
            }
            let link = document.getElementById(type+index);
            let copysuccess;
            let dummy = document.createElement("input");
            document.body.appendChild(dummy);
            dummy.setAttribute("id", "dummy_id");
            document.getElementById("dummy_id").value = link.value;
            dummy.select();
            try {
                copysuccess = document.execCommand("copy");
            } catch (e) {
                copysuccess = false
            }
            document.body.removeChild(dummy);
            if(copysuccess)
            console.log(c+index)
            const button=document.getElementById(c+index).innerHTML="Link Copied"
            console.log(button);

        },

        
        async getPreviewData(){
            var data = await this.previewAgreementInfo({"id":this.$route.params.id ,"type":this.$route.params.type});
            this.agreementCity = data.data.city;
            this.agreementName = data.data.package;
            this.biometricData=data.biometricData;
            this.esignInfoData=data.esignInfoData;
            this.reqData = data.data;
            this.agreementStatus = data.agreementStatus; 
            console.log(data);
        },
        esignPartyStatus(nameData){
            for(let i=0;i<this.esignInfoData.length;i++){
                if(this.esignInfoData[i].Name==nameData && this.esignInfoData[i].EsignStatus){
                    return true
                }
            }
            return false  
        },
        esignLink(name){
            for(let i=0;i<this.esignInfoData.length;i++){
                if(this.esignInfoData[i].Name==name){
                    return this.esignInfoData[i].EsignUrl
                }
            }
            return ''
        },

        landlordEditMode(type,data){
            for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+data).disabled = false;
            }
            this.landlordoffset=data 
            this.tenantoffset=-1
            this.witnessoffset=-1
        },
        landlordsave(type,data){
            for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+data).disabled = true;
            }
            this.landlordoffset=-1
        },
        tenantEditMode(type,data){
            for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+data).disabled = false;
            }
            this.tenantoffset=data
            this.landlordoffset=-1;
            this.witnessoffset=-1;
        },
        tenantsave(type,data){
            for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+data).disabled = true;
            }
            this.tenantoffset=-1
        },
        witnessEditMode(type,data){
            for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+data).disabled = false;
            }
            this.witnessoffset=data;
            this.landlordoffset=-1;
            this.tenantoffset=-1;
        },
        witnessSave(type,data){
            for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+data).disabled = true;
            }
            this.witnessoffset=-1

        },
     
    },
    beforeMount(){
        this.getPreviewData();
    },
      
}
</script>
<style scoped>

</style>