import { esignService } from "@/_services/esign.service"
import { router } from '../../_helpers/router';

const state = {
    data: {}
}

// const getters = {
// getData(state) {
//     if (state.data && state.data.length > 0)
//         return state.data;
//     else
//         return getItemFromStorage(keyName);
// },
// }


const actions = {
    async esignconfirmation({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            const response = await esignService.esignconfirmation(data);
            console.log(response.data)
            if (response.data && response.data['status'])
                return {"status": true};
            else {
                commit('AgreementFailure', data.id);
                dispatch('alert/error', response.data['message'], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async bioMetric({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            const response = await esignService.bioMetric(data);
            if (response.data && response.data['status'] && response.data['status'] == true)
                commit('BioMetricSuccess')
            else {
                commit('AgreementFailure', data.id);
                dispatch('alert/error', response.data['message'], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async esignStatus({ dispatch, commit }, paymentRefNo) {
        try {
            const response = await esignService.esignStatus(paymentRefNo);
            if (response.data && response.data['esignStatus'] == true) {
                commit('EsignSuccess')
            } else {
                //dispatch('alert/error',  response.data.message, { root: true });
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async bioMetricStatus({ dispatch, commit }, paymentRefNo) {
        try {
            const response = await esignService.bioMetricStatus(paymentRefNo);
            // console.log(response.data)
            if (response.data && response.data['bioMetricStatus']) {
                commit('BioMetricSuccess')
            } else {
                //dispatch('alert/error',  response.data.message, { root: true });
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    }
}


const mutations = {
    AgreementRequest(state) {
        state.status = { requesting: true };
    },
    AgreementSuccess(state, Id) {
        state.status = { TransactionId: Id };
    },
    AgreementFailure(state, Id) {
        state.status = { requesting: false, TransactionId: Id };
        if (Id && Id !== '' && Id !== null) {
            state.status.TransactionId = Id;
        }
    },
    BioMetricSuccess(state) {
        state.status = { requesting: false }
        router.push('/verification/bio-metric');
    },
    EsignSuccess(state) {
        state.status = { requesting: false }
        router.push('/verification/esign');
    }
}

export const esignBiometric = {
    namespaced: true,
    state,
    // getters,
    actions,
    mutations
}