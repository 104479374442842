<template class="custom-select">
  <select
    @change="handleChange"
    ref="$_VueXeditable_Select" @blur="checkblur" :required="required"
    :name="name"
    :attribute="attribute"
    :index="index"
  >
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="internalOptionValue(option)"
      :selected="isSelected(option)"
      :disabled="isDisabled(option)"
      ref="options"
    >
      {{ displayedOptionValue(option) }}
    </option>
  </select>
</template>

<script>
  export default {
    template: '#custom-select',
    props: ['value', 'options','required','attribute','name','index'],
    mounted () {
      this.$refs.$_VueXeditable_Select.addEventListener('keydown', this.onKeyDown, false)
    },
    beforeDestroy () {
      this.$refs.$_VueXeditable_Select.removeEventListener('keydown', this.onKeyDown, false)
    },
    methods: {
      checkblur(){
        this.$emit('blur', 1)
      },
      displayedOptionValue (option) {
        if (Array.isArray(option)) {
          return option[option.length - 1]
        } else {
          return option
        }
      },
      internalOptionValue (option) {
        if(this.options[0] == option){
          return " "
        }else{
          return option
        }
      },
      isDisabled(option){
        if(this.value == option){
          return true
        }else if(this.options[0] == option){
          if( this.options[0]=='Country Code'||this.options[0]=='Title'||this.options[0]=='ID Type'){
            return true
          }else{
            return false
          }
        }else{
          false
        }
      },
      isSelected (option) {
        if(this.value == option){
          return "selected"
        }else if(this.options[0] == option){
          // option)
          return "selected"
        }else{
          false
        }
      },
      onKeyDown (e) {
        this.$emit('keydown', e)
      },
      handleChange (event) {
        const value = this.options.find(o => {
          return this.internalOptionValue(o) === event.target.value
        });
        this.$emit('input', value)
      }
    }
  }
</script>
