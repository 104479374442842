<template>
    <div class="container">
        <div class="transaction">
            <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <h2 class="esigntabHeading pt-30 pb-15">E-Signing Recipients</h2>
                <p class="pb-30">Please verify the email Id and Phone No of participants as Agreement will be sent to these Ids for E-signing.<br>
                    <span class="text-danger text-bold">Note: Once Submitted the Email ID and Phone No can't be changed later.</span>
                </p>
                <div class="table-responsive esignDiv">
                    <table cellspacing="0" class="table  table-bordered e-sign">
                        <thead>
                            <tr>
                                <th width="90">S. No.</th>
                                <th>Name</th>
                                <th>Role</th>
                                 <th >Phone</th>
                                <th class="emailEedit">E-mail</th>
                                <th class="action">Action</th>
                            </tr>
                        </thead>
                        <tbody v-for="(landlord,index) in reqData.landlord" :key="index">
                            <tr id="0">
                                <td>{{index + 1}}</td>
                                <td>{{landlord.ownerName}} </td>
                                <td>Owner</td>
                                <td><span v-show="landlordOffset != index">{{landlord.ownerCountryCode}} -  {{landlord.ownerMobile}}</span>
                                <div v-show="landlordOffset == index" >
                                     <div class="group-phone bracket">
                                     <div class="inputSelct" >
                                    <select  class="form-control" v-model="landlord.ownerCountryCode">
                                        <option v-for="(code,index ) in countryCodeOptions" :key="index" >
                                                {{code }}
                                        </option>
                                    </select>
                                     </div>
                                     <input  :required="true"  type = "number" maxlength = "15" :id="'landlordPhone'+index" disabled="true"    @keypress="isMobile($event)" @blur="stopEditing"   pattern="^[0-9]{7,15}$" class="form-control sharvalue emailInput" v-model="landlord.ownerMobile"></div>
                                     </div>
                                     </td>
                                  <td class="emailEedit"><span v-show="landlordOffset != index">{{landlord.ownerEmail}}</span><input v-show="landlordOffset == index" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"  :required="landlord.ownerEmail!==''?true:false"  type="text" :id="'landlordEmail'+index" disabled="true" @blur="stopEditing"  class="form-control sharvalue emailInput" v-model="landlord.ownerEmail"></td>
                                    <td width="150">
                                    <div class="addNewBox" >
                                    <span  v-show="landlordOffset != index"  @click.prevent="landlordEditing(['landlordPhone','landlordEmail'],index)" >
                                            <em class="icon-edit"></em>
                                    </span>
                                    <span  @click="updatelandlord(['landlordPhone','landlordEmail'],index)" v-show="landlordOffset==index"><em class="icon-check"></em></span>        
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-for="(tenant,index) in reqData.tenant" :key="index+'A'">
                            <tr>
                                <td v-if="reqData.landlord.length >0">{{reqData.landlord.length + index + 1}}</td>
                                <td v-else>{{index +1}}</td>
                                <td>{{tenant.tenantName}}</td>
                                <td>Tenant</td>
                                <td><span v-show="tenantOffset != index">{{ tenant.tenantCountryCode }} - {{tenant.tenantMobile}}</span>
                                <div class="group-phone bracket"  v-show="tenantOffset == index">
                                    <div class="inputSelct" >
                                         <select class="form-control" v-model = "tenant.tenantCountryCode">
                                            <option  v-for="(code,index ) in countryCodeOptions" :key="index">{{ code }}</option>
                                        </select>
                                    </div>
                                <input  :required="true"  type = "number" pattern="^[0-9]{7,15}$"  maxlength = "15" :id="'tenantPhone'+index" disabled="true" @keypress="isMobile($event)" class="form-control sharvalue emailInput" v-model="tenant.tenantMobile" @blur="stopEditing" >
                                </div> 
                                </td>
                                
                                <td><span  v-show="tenantOffset != index">{{tenant.tenantEmail}}</span><input v-show="tenantOffset == index" type="text" :id="'tenantEmail'+index" disabled="true" :required="tenant.tenantEmail!==''?true:false" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  class="form-control sharvalue emailInput" v-model="tenant.tenantEmail" @blur="stopEditing" ></td>
                                <td width="150">
                                <div class="addNewBox" >
                                     <span  v-show="tenantOffset != index"  @click.prevent="tenantEditing(['tenantPhone','tenantEmail'],index)" >
                                         <em class="icon-edit"></em>
                                    </span>
                                    <span  @click="updatetenant(['tenantPhone','tenantEmail'],index)" v-show="tenantOffset==index"><em class="icon-check"></em></span>  
                                </div>
                                </td>
                            </tr>
                        </tbody> 
                    </table>
                </div>
            </div>
            </div>
            <div class="stepButton"><button type="button" @click="exportToPDF" id="submitButton" class="next">Submit</button></div>
                
        </div>
        <div v-show="showPDF">
            <div ref="document">
                <div class="esignPDF pdfDownload">
                    <components :is="getAgreementComponent" :esignData="previewData" :currentCity="agreementCity" :readOnly="true" :stamp="false" />
                </div>
            </div>
        </div>
        <overlay :openModel="openModel"></overlay>
    </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
// import html2pdf from 'html2pdf.js'
import {defaultView, MumbaiLeaseAgreement,LeaseAgreement} from './templates'
import overlay from './templates/overlay'


export default {
    data(){
        return {
            reqData:{},
            fileData:"",
            agreementCity:'',
            agreementName:'',
            previewData:'',
            showPDF:false,
            openModel:"",
            btnActiveLandlord:true,
            btnActiveTenant:true,
            landlordOffset:-1,
            tenantOffset: -1,
            countryCodeOptions: ["+91", "+61", "+852", "+968", "+974", "+65", "+971", "+44", "+1", "+27", "+60", "+64", "+66", "+966", "+31", "+973", "+54", "+43", "+880", "+32", "+55", "+86", "+385", "+42", "+45", "+1809", "+20", "+358", "+679", "+33", "+49", "+30", "+592", "+36", "+62", "+353", "+972"],
        }
    },
     computed: {
         ...mapGetters("master", { masterData: "getData" }),
        getAgreementComponent:function(){
            return "LeaseAgreement";
        }
    },
    components:{
        MumbaiLeaseAgreement,defaultView,LeaseAgreement,overlay
    },
    beforeCreate: function() {
        document.body.className = 'bg-white';
    },
    methods:{
        ...mapActions('agreement',["previewAgreementInfo"]),
        ...mapActions('esignBiometric', {
            esignconfirmation: 'esignconfirmation',esignStatus:"esignStatus"
        }),
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async exportToPDF () {
            var sharemoda = document.getElementsByClassName('sharvalue');
            for (let i = 0; i < sharemoda.length; i++) {
              if (sharemoda[i].getAttribute('required') == true && sharemoda[i].value == "") {
                  console.log(sharemoda[i].getAttribute('required'), sharemoda[i].value);
                  sharemoda[i].classList.add('properyInfoError');
              }
            }
            if(document.getElementsByClassName('properyInfoError').length <1){
            this.openModel = "loader";
            const data = {
                "id":this.$route.params.id,
                "agreementData":this.reqData,
                "fileName":this.reqData.city + " Rental Agreement",
            };
            const confirmingEsign = await this.esignconfirmation(data);
            console.log(confirmingEsign)
            this.openModel = ""
            if(confirmingEsign.status){
                window.parent.postMessage({type:'myAgreement'}, '*');
            }
        }       
        },
        async getPreviewData(){
            var data = await this.previewAgreementInfo({"id":this.$route.params.id});
            this.agreementCity = data.data.city;
            this.agreementName = data.data.package;
            this.reqData = data.data;
            this.previewData = data;
        },
        tenantEditing(type,index) {
            var sharemoda = document.getElementsByClassName('sharvalue');
            // console.log(sharemoda)
            for (let i = 0; i < sharemoda.length; i++) {
              if (sharemoda[i].getAttribute('required') == true && sharemoda[i].value == "") {
                  console.log(sharemoda[i]);
                  sharemoda[i].classList.add('properyInfoError');
                }
            }
            console.log(document.getElementsByClassName('properyInfoError').length)
           if(document.getElementsByClassName('properyInfoError').length <1){
                 for(let i=0;i<type.length;i++){
                if(document.getElementById(type[i]+index)){
                      document.getElementById(type[i]+index).disabled = false;
                }
            }
            this.tenantOffset=index
            this.landlordOffset=-1;
           }  
        },
        landlordEditing(type,index) {
            var sharemoda = document.getElementsByClassName('sharvalue');
            for (let i = 0; i < sharemoda.length; i++) {
              if (sharemoda[i].getAttribute('required') == true && sharemoda[i].value == "") {
                //   console.log(sharemoda[i].getAttribute('required'), sharemoda[i].value);
                  sharemoda[i].classList.add('properyInfoError');
              }
          }
           if(document.getElementsByClassName('properyInfoError').length <1){
             for(let i=0;i<type.length;i++){
                const data=document.getElementById(type[i]+index);
                if(data){
                       document.getElementById(type[i]+index).disabled = false;
                }
            }
            this.landlordOffset=index 
            this.tenantOffset=-1
           }     
        },
        updatelandlord(type,index) {
            var sharemoda = document.getElementsByClassName('sharvalue');
            for (let i = 0; i < sharemoda.length; i++) {
              if (sharemoda[i].getAttribute('required') == true && sharemoda[i].value == "") {
                //   console.log(sharemoda[i].getAttribute('required'), sharemoda[i].value);
                  sharemoda[i].classList.add('properyInfoError');
              }
            } 
            if(document.getElementsByClassName('properyInfoError').length <1){
             for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+index).disabled = true;
            }
            this.landlordOffset=-1
           }
        },
        updatetenant(type,index) {
            var sharemoda = document.getElementsByClassName('sharvalue');
            for (let i = 0; i < sharemoda.length; i++) {
              if (sharemoda[i].getAttribute('required') == true && sharemoda[i].value == "") {
                //   console.log(sharemoda[i].getAttribute('required'), sharemoda[i].value);
                  sharemoda[i].classList.add('properyInfoError');
              }
            }
            if(document.getElementsByClassName('properyInfoError').length <1){
              for(let i=0;i<type.length;i++){
               document.getElementById(type[i]+index).disabled = true;
            }
            this.tenantOffset=-1
          } 
        },
        validateEmail(email) {
           var re = /\S+@\S+\.\S+/;
           return re.test(email);
         },
        isMobile(event){
            let char = String.fromCharCode(event.keyCode);  // Get the character
            if(/^[0-9]+$/.test(char)){
            if(event.target.value.length<15){
                return true;
             }else{
                event.preventDefault(); 
                return false
               }
        } 
        else {event.preventDefault(); return false}
        },
        isIndianMobile(event){
             let char = String.fromCharCode(event.keyCode);  // Get the character
            if(/^[0-9]+$/.test(char)){
            if(event.target.value.length<10){
                return true;
             }else{
                event.preventDefault(); 
                return false
               }
        } 
        else {event.preventDefault(); return false}
        },
         stopEditing(event) {
            if (event && event.target && event.target.pattern) {
                var pattern = new RegExp(event.target.pattern)
                var check = pattern.test(event.target.value);
                console.log(check);
                if (!check) {
                    event.target.value = "";
                    event.target.placeholder = "Please Enter a Valid Value";
                    event.target.classList.add("properyInfoError");
                } else {
                    event.target.classList.remove("properyInfoError");
                }
            } else {
                const selectV = event.target.value;
                if (selectV) {
                    event.target.classList.remove("properyInfoError");
                } else {
                    event.target.classList.add("properyInfoError");
                }
            }
        },
        // editMode(type,data){
        //     console.log(type+" "+data);
        //       document.getElementById(type+data).disabled = false;
        //      this.landlordOffset = data
        //     document.getElementById(type+data).disabled = false;
        // },
        // saveMode(type,data){
        //     console.log(type,data)
        //     document.getElementById(type+data).disabled = true;
        //     this.landlordOffset = -1;
        // },
    },
    beforeMount(){
        this.esignStatus(this.$route.params.id); 
        this.getPreviewData();
    },
}
</script>