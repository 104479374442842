import httpClient from '@/_helpers/http-client';
import { authHeader } from "../_helpers/auth-header"

const createInvoice = async(data) => {
    const response = await httpClient.post('/payment/create-invoice', data, authHeader());
    return response;
}
const agreementAmountNew = async (data) => {
    var response = await httpClient.post('/payment/agreement-amount', data, authHeader());
    return response;
}
const paymentStatus = async(paymentRefNo) => {
        const _body = {
            id: paymentRefNo
        }
        const response = await httpClient.post('/payment/status', _body, authHeader());
        return response;
    }
    // const updatePaymentCreator = async (data) => {
    //     const response = await httpClient.post('/updatePaymentCreator', data, authHeader());
    //     return response;
    // }

export const paymentService = {
    //paymentAgreement,
    createInvoice,
    paymentStatus,
    agreementAmountNew
    // updatePaymentCreator
};