import httpClient from '@/_helpers/http-client';
import { authHeader } from "../_helpers/auth-header"

const userLogin = async(data) => {
    var response = await httpClient.post('/user-login', data, authHeader());
    return response
}

export {
    userLogin
};