import httpClient from '@/_helpers/http-client';
// import { authHeader } from "../_helpers/auth-header"

const biometricLocalities = async(data) => {
    var response = await httpClient.post('/biometric-support-localities', data)
    return response;
}
const bioMetricAddress = async(data) => {
    var response = await httpClient.post('/add-biometric-support-address', data)
    return response;
}
const bioMetricDetails = async(data) => {
    var response = await httpClient.post('/parties-biometric-address-details', data)
    return response;
}
const partiesBiometric = async(data) => {
    var response = await httpClient.post('/add-parties-biometric-address', data)
    return response;
}


export  {
    biometricLocalities,bioMetricAddress,bioMetricDetails,partiesBiometric
}

// /add-biometric-support-address
// /add-parties-biometric-address
// /parties-biometric-address-details