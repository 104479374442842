import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import { master } from './modules/master.module';
import { vendor } from './modules/vendor.module';
import { bioMetric } from './modules/bioMetric.module';
import { agreement } from './modules/agreement.module';
import { payment } from './modules/payment.module';
import { esignBiometric } from './modules/esignBiometric.module';
import { coupon } from './modules/coupon.module';
import { alert } from './modules/alert.module';

export default new Vuex.Store({
    modules: {
        master,
        agreement,
        payment,
        esignBiometric,
        coupon,
        alert,
        vendor,
        bioMetric
    }
});