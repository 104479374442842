<template>
<div class="container">
    <div class="transaction">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <h2 class="esigntabHeading pt-30 pb-15">Agreement List</h2>
            </div>
            <div class="filterGroup col-lg-12 col-sm-12 col-md-12">
                <div class="form-group">
                <label for="" class="label">Reference No</label>
                    <input type="text" name="referenceNo" class="form-control" placeholder="Reference No" v-model="referenceNo" >
                </div>
                <div class="form-group">
                    <label for="" class="label">City</label>
                    <select class="form-control" v-model="selectedCity">
                            <option disabled value="" selected>-Select-</option>
                            <option v-for="(city,index) in cities" :key="index">{{city}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="" class="label">Service Type</label>
                    <select class="form-control" v-model="selectedService">
                            <option disabled value="" selected>-Select-</option>
                            <option v-for="(service,index) in services" :key="index">{{service}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="" class="label">Agreement Type</label>
                    <select class="form-control" v-model="selectedAgreementType">
                            <option disabled value="" selected>-Select-</option>
                            <option v-for="(agreementType,index) in agreementTypes" :key="index">{{agreementType}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="" class="label">Agreement Status</label>
                    <select class="form-control" v-model="selectedAgreementType">
                            <option disabled value="" selected>-Select-</option>
                            <option v-for="(agreementType,index) in agreementTypes" :key="index">{{agreementType}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="" class="label">Filter By</label>
                    <select   @change="onPaginate($event)" class="form-control" v-model="pageLimit">
                        <option disabled value="" selected>-Select-</option>
                        <option v-for="(pagevalue,index) in paging" :value="pagevalue" :key="index">{{pagevalue}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <button class="btn">Apply Filter</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="table-responsive">
                    <table cellspacing="0" class="table  table-bordered beatsTable">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th width="150">Reference No</th>
                                <th>City-Name</th>
                                <th>Service</th>
                                <th>Service Type</th>
                                <th>Agreement Type</th>
                                <th>Agreement Status</th>
                                <th>Created By</th>
                                <th>Creation Date</th>
                                <th>Assign Vendor</th>
                                <th >View Details</th>
                                <th>Action</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(agreement,index) in completeData" :key="index">
                                <td>{{index+1}}</td>
                                <td>{{agreement.UniqueId}}</td>
                                <td>{{agreement.AgreementCityServiceXref.location.Name}}</td>
                                <td>{{agreement.AgreementCityServiceXref.Type.RefName}}</td>
                                <td>{{agreement.AgreementCityServiceXref.ServiceZref.RefName}}</td>
                                <td>{{agreement.AgreementCityServiceXref.Agreement.Name}}</td>
                                <td>{{agreement.Status}}</td>
                                <td>{{agreement.User.Name}}</td>
                                <td>{{giveDate(agreement.createdAt)}}</td>
                                <td v-if="agreement.AgreementCityServiceXref.location.Name=='Mumbai'">
                                    <span :id="'vendorName'+index" v-if="agreement.AgreementInfoVendorXref==null">--</span>
                                    <span  :id="'vendorName'+index" v-else>{{dropdown ? '--':agreement.AgreementInfoVendorXref.BiometricVendor.ContactPerson}}</span>
                                    <span v-if="dropdown">
                                    <select  @change="onChange($event,agreement.Id,index)"  :id="'vendor'+index"  >
                                        <option disabled value="" selected>Select</option>
                                    <option v-for="(vendor,index) in vendors " :key="index+'A'" :value="vendor.Id"   :data-name="vendor.ContactPerson" >{{vendor.ContactPerson}}</option>
                                    </select>
                                    </span>
                                    <span @click="showDropown" v-else><br/><strong>assign</strong></span>
                                </td>
                                <td v-else></td>
                                <td><span class="addNewBox"><span class="pdfView" @click="getPreviewData(agreement.UniqueId)"><em class="icon-view"></em></span></span></td>
                                <td></td>
                                <td> <span class="downloadIcon" @click="exportToPDF(agreement.UniqueId)"><em class="icon-download"></em></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <paginate
                    v-model="page"
                    :page-count="pageCount"
                    :margin-pages="2"
                    :page-range="5"
                    :click-handler="paginatePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :page-link-class="'page-link-item'"
                    :prev-class="'prev-item'"
                    :prev-link-class="'prev-link-item'"
                    :next-class="'next-item'"
                    :next-link-class="'next-link-item'"
                    :break-view-class="'break-view'"
                    :break-view-link-class="'break-view-link'"
                    :first-last-button="false"
                >
                
                </paginate>
            </div>
        </div>
    </div>
    <div class="modelOverlay" :class="{active : modelPrivew == true}" modelPrivew></div>
    <div class="agrmentPrivewModel" v-if="modelPrivew == true">
        <div class="container">
            <div class="row">
                <div class="aggrementBox">
                    <div class="sumbdrBox">
                        <button @click="closePrivew" class="closePrivew"><em class="icon-close"></em></button>
                        <div class="sumBody waterMark usSelectNone" ref="document">
                            <component :is="componentName" :readOnly="true" :currentCity="agreementCity" :esignData="previewData"  :stamp="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
// import { getItemFromStorage } from "@/_helpers/utility"
import {  mapActions } from "vuex";
import { defaultView, MumbaiLeaseAgreement,LeaseAgreement} from './agreements/templates';
import html2pdf from 'html2pdf.js'
import Paginate from 'vuejs-paginate';

export default {
    name:'BeatsAgreementList',
    data(){
        return{
            referenceNo:'',
            pageLimit:5,
            page:0,
            pageCount:0,
            completeData:'',
            componentName: "defaultView",
            agreementCity:'',
            previewData:"",
            agreementName:"",
            selectedVendor:'',
            vendors:[],
            modelPrivew:"",
            dropdown:false,
            cities:["Gurgaon","Mumbai"],
            paging:[5,10],
            selectedCity:'',
            services:["Agreement"],
            selectedService:'',
            agreementTypes:['Rent Agreement'],
            selectedAgreementType:''
        }
    },
    components:{
        defaultView,MumbaiLeaseAgreement,LeaseAgreement,Paginate
    },
    beforeCreate: function() {
        document.body.className = 'bg-white';
    },
    methods:{
         ...mapActions("agreement",["completeAgreementInfo","previewAgreementInfo"]),
         ...mapActions("vendor",["getVendors",'assignVendor']),

          async completeAgreement(page,limit){
              const pageNo = page!=null?page:this.page;
              const limitNo = limit!=null?limit:this.pageLimit;
            //   console.log(pageNo)
               var data = await this.completeAgreementInfo({ "page":pageNo,"limit":limitNo});
               console.log(data)
               this.completeData=data.data;
               this.pageCount=data.count/limitNo;
              
          },
          async showDropown(){
             this.dropdown=true;
          },
          async vendorsInfo(){
              var data= await this.getVendors()
              if(data.status){
                 this.vendors=data.data;
              }
              
          },
          paginatePage(pageNum){
              pageNum = pageNum-1;
              this.page=pageNum
            //   console.log(pageNum)
            this.completeAgreement(pageNum,this.pageLimit);
          },
         async onPaginate(e){
            this.pageLimit=e.target.value;
            this.completeAgreement(this.page,this.pageLimit)
         },
       
        async onChange(event,agreementId,index){
            this.selectedVendor=event.target.value;
            this.dropdown=false
            await this.assignVendor({id:agreementId,vendorId:this.selectedVendor})
            document.getElementById('vendorName'+index).innerHTML=event.target.options[event.target.selectedIndex].dataset.name;
            console.log(this.selectedVendor)
        },

        async exportToPDF (id) {
            await this.getPreviewData(id);
            this.modelPrivew = false;
           var pdfContent = this.$refs.document.innerHTML;
           var opt = {
                margin:       [20, 2, 30, 2],
                image:        { type: 'jpeg', quality: 0.98 },
                jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
            };
            await html2pdf().from(pdfContent).set(opt).toPdf().get('pdf').save(id+'.pdf')
        },

            async getPreviewData(id){
            var data = await this.previewAgreementInfo({"id":id,"type":2});
            this.previewData=data;
            this.modelPrivew = true;
            if(data.status){
                let pckgename=data.data.package.replace(/ /g,"");
                this.agreementCity = data.data.city;
                this.agreementName = data.data.package;
                if(pckgename=='RentalAgreement' || pckgename == 'RentalAgreement+PoliceVerification'){
                    if(this.agreementCity == 'Mumbai' || this.agreementCity == 'Pune' || this.agreementCity == 'Thane' || this.agreementCity == 'Navi Mumbai'){
                        this.componentName ='MumbaiLeaseAgreement'
                    }else{
                       this.componentName = 'LeaseAgreement'
                    }  
                    }else{
                        this.componentName = 'defaultView';
                }
            }

        },
         giveDate(data){
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const newMonth = months[date.getMonth()]
            const day = date.getDate();
            const year = date.getFullYear();
            return day + " " + newMonth + " " + year;
        },
         closePrivew(){
            this.modelPrivew = false;
        },
          
    },
    async beforeMount(){
        await this.completeAgreement();
        await this.vendorsInfo();
       
    },
    computed:{
         
    }
}
</script>
