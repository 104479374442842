<template>
  <div class='vue-xeditable' :class="{'enabled': enableEditing}">
    <slot name="before" v-if="isRemoteUpdating"></slot>

    <span
      :class="{'vue-xeditable-empty':finallySubmitting && required&& $_VueXeditable_isValueEmpty, 'vue-xeditable-value': enableEditing}"
      :title='(enableEditing) ? titleEnabled : titleDisabled'
      v-show='(!isEditing && !isRemoteUpdating) || !enableEditing'
      v-on:click='$_VueXeditable_maybeStartEditing(1, $event)'
      v-on:dblclick='$_VueXeditable_maybeStartEditing(2, $event)'
      v-html='$_VueXeditable_getHTMLValue()'>
    </span>

    <div
      v-show='isEditing && !isRemoteUpdating && enableEditing'
      class='vue-xeditable-control'
    >

      <input
        v-if='type === "text"'
        class='vue-xeditable-form-control'
        type="text"
        :name="name"
        :attribute="attribute"
        :index="index"
        :value="rawValue"
        @keypress="validateInputs"
        @keydown='$_VueXeditable_onKeydown'
        @blur="$_VueXeditable_stopEditing"
        @focus="ShowHelpText"
        :required="required"
      >
      <textarea
        v-else-if='type === "textarea"'
        class='vue-xeditable-form-control'
        :value="rawValue"
        @keypress="validateInputs"
        @keydown='$_VueXeditable_onKeydown'
        @blur="$_VueXeditable_stopEditing"
        @focus="ShowHelpText"
        :required="required"
      >
      </textarea>

      <input
        v-else-if='type === "number"'
        class='vue-xeditable-form-control'
        type="number"
        :value="rawValue"
        :name="name"
        :attribute="attribute"
        :index="index"
        @keypress="validateInputs"
        onkeydown="javascript: return event.keyCode == 69 ? false : true"
        @keydown='$_VueXeditable_onKeydown'
        @blur="$_VueXeditable_stopEditing"
        @focus="ShowHelpText"
        :pattern="pattern"
        :required="required"
      >
      <input
        v-else-if='type === "boolean"'
        class='vue-xeditable-form-control'
        type="checkbox"
        :value="rawValue"
        @change='$_VueXeditable_valueDidChange'
        @focus="ShowHelpText"
        :required="required"
      >

      <x-custom-select
        v-else-if='type === "select"'
        class='vue-xeditable-form-control'
        :value="rawValue"
        :options="options"
        :name="name"
        :index="index"
        :attribute="attribute"
        :required="required"
        @input='$_VueXeditable_valueDidChange'
        @change="$_VueXeditable_onKeydown"
        @blur="$_VueXeditable_stopEditing"
        @focus="ShowHelpText"
      >
      </x-custom-select>

      <date-picker
        v-else-if='type === "date"'
        :value="rawValue"
        :name="name"
        :attribute="attribute"
        @selected="$_VueXeditable_valueDidChange"
        @focus="ShowHelpText"
        input-class="vue-xeditable-form-control"
        placeholder="pick a date"
        :required="required"
      >
      </date-picker>
    </div>
    <slot name="after" v-if="isRemoteUpdating"></slot>
  </div>
</template>

<script>
  import axios from 'axios'
  import XCustomSelect from './XCustomSelect.vue'
  import DatePicker from 'vuejs-datepicker'

  export default {
    name: 'vue-xeditable',
    components: {XCustomSelect, DatePicker},
    props: {
      value: {
        type: [String, Number, Array, Boolean, Date]
      },
      type: {
        type: String,
        required: false,
        default: 'text'
      },
      attribute:{
        type: String,
        required: false,
        default: ''
      },
      validateText:{
        type: String,
        required: false,
        default: ''
      },
      currentTabId:{
        type: String,
        required: false,
        default: ''
      },
      nextTabId:{
        type: String,
        required: false,
        default: ''
      },
      index:{
        type: Number,
        required: false,
        default: 0
      },
      rawData:{
        type: [String, Number, Array, Boolean, Date],
        required: false,
        default: ''
      },
      name: {
        type: String,
        required: false,
        default: 'VueXeditableDefaultName'
      },
      pattern: {
        type: String,
        required: false,
        default: ''
      },
      empty: {
        type: String,
        required: false,
        default: 'Empty'
      },
      placeholder: {
        type: String,
        required: false,
        default: ''
      },
      helpingText: {
        type: String,
        required: false,
        default: ''
      },
      errorText: {
        type: String,
        required: false,
        default: ''
      },
      amountFormat:{
        type: Boolean,
        required:false,
        default:false
      },
      clauseLimit:{
        type: Boolean,
        required:false,
        default:false
      },
      finallySubmitting:{
        type: Boolean,
        required:false,
        default:false
      },
      options: {
        type: Array,
        default: function () {
          return []
        }
      },
      editOnDoubleClick: {
        type: Boolean,
        required: false,
        default: false
      },
      required: {
        type: Boolean,
        required: false,
        default: false
      },
      remote: {
        type: Object,
        required: false,
        default: function () {
          return {
            url: null,
            method: 'PATCH',
            key: null,
            resource: null,
            headers: null
          }
        }
      },
      enableEditing: {
        type: Boolean,
        required: false,
        default: true
      },
      titleEnabled: {
        type: String,
        required: false,
        default: ''
      },
      titleDisabled: {
        type: String,
        required: false,
        default: ''
      }
    },
    data () {
      return {
        isEditing: false,
        isRemoteUpdating: false,
        rawValue: this.value
      }
    },
    watch: {
      value (newValue) {
        this.rawValue = newValue
      },
      options (newOptions) {
        this.rawValue = newOptions.find((o) => {
          const v = (Array.isArray(o)) ? o[0] : o
          return v === this.rawValue
        })
      }
    },
    computed: {
      $_VueXeditable_isValueEmpty () {
        return (this.rawValue === null || this.rawValue === undefined || this.rawValue === '')
      },
      $_VueXeditable_hasRemoteUpdate () {
        return this.remote && this.remote.url && this.remote.url.length && this.remote.key && this.remote.key.length
      },
      $_VueXeditable_hasValidRemote () {
        return this.$_VueXeditable_hasRemoteUpdate && ['PATCH', 'PUT', 'POST'].includes(this.remote.method.toUpperCase())
      }
    },
    mounted(){
      this.$root.$on('changeTab'+this.currentTabId,(data)=>{
        console.log(data);
        this.isEditing = true;
        //this.$_VueXeditable_startEditing();
      })
    },
    methods: {
      validateInputs(event){
        console.log(this.validateText);
        if(this.validateText){
        this[this.validateText](event)
        }
      },
      isAlphabet(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[A-Za-z ]+$/.test(char)){ return true;} // Match with regex 
        else {event.preventDefault();return false}
      },
      isEmail(event){
          // let char = String.fromCharCode(event.keyCode); // Get the character
        if(/([A-Z0-9a-z_-][^@])+?@[^$#<>?]+?\.[\w]{2,4}/.test(event.target.value)){ 
            return true;} // Match with regex 
        else {
            event.target.value="";
            return false}
      },
      isMiscellanous(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        console.log(event.target.value.length)
        if(event.target.value.length < 1000){
          this.$emit('clause-length',999- event.target.value.length,this.index,event.target.value)
        }else{
          this.$emit('clause-length',0,this.index,event.target.value)
        }
        if(/[0-9a-zA-Z\s,-,/,.,-]+$/.test(char)){ 
            if(event.target.value.length<1000){
            return true;
          }else{
            event.preventDefault(); return false
          }
          } // Match with regex 
        else {
            // event.target.value=event.target.value.slice(0,-1);
            event.preventDefault();
            return false}
      },
      isAlphaNumeric(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[A-Za-z0-9]+$/.test(char)){ return true;} // Match with regex 
        else {event.preventDefault();return false}
      },
      isNumeric(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[0-9]+$/.test(char)){return true;} // Match with regex 
        else {event.preventDefault(); return false}
      },
      isPincode(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[0-9]+$/.test(char)){
          if(event.target.value.length<6){
            return true;
          }else{
            event.preventDefault(); return false
          }
          
          } // Match with regex 
        else {event.preventDefault(); return false}
      },
      isMobile(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[0-9]+$/.test(char)){
          if(event.target.value.length<15){
            return true;
          }else{
            event.preventDefault(); return false
          }  
          } // Match with regex 
        else {event.preventDefault(); return false}
      },
         isIndianMobile(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[0-9]+$/.test(char)){
          if(event.target.value.length<10){
            return true;
          }else{
            event.preventDefault(); return false
          }
          
          } // Match with regex 
        else {event.preventDefault(); return false}
      },
      isAadhar(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[0-9]+$/.test(char)){
          if(event.target.value.length<12){
            return true;
          }else{
            event.preventDefault(); return false
          }
          
          } // Match with regex 
        else {event.preventDefault(); return false}
      },
      isPassport(event){
        let char = String.fromCharCode(event.keyCode); // Get the character
        if(/^[A-Za-z0-9]+$/.test(char)){
          if(event.target.value.length<9){
            return true;
          }else{
            event.preventDefault(); return false
          }
          
          } // Match with regex 
        else {event.preventDefault(); return false}
      },
      isPan(event){
         if(event.target.value.length<10){
            return true;
          }else{
            event.preventDefault(); return false
          }
      },
      $_VueXeditable_getHTMLValue () {
        if(this.type === 'textarea'){
          if(this.rawValue !== null && this.rawValue !== ""){
            return this.rawValue
          }else{
            return this.placeholder
          }
          
        }
        if (this.$_VueXeditable_isValueEmpty) {
          return this.placeholder
        } else if (this.type === 'select' && Array.isArray(this.rawValue)) {
          return this.rawValue[this.rawValue.length - 1]
        } else if (this.rawValue === undefined || this.rawValue === null) {
          return '?'
        } else if (this.type === 'date') {
          return this.rawValue.toLocaleString()
        }
        return this.rawValue
      },
      ShowHelpText(){
        // do
        if(document.getElementsByClassName("vibrate").length > 0){
          document.getElementsByClassName("vibrate")[0].classList.remove("vibrate");
        }
        if(this.helpingText){
        document.getElementsByClassName("helpText")[0].style.display = "block";
        document.getElementById("helpingtext").innerText = this.helpingText;
        }else{
          document.getElementsByClassName("helpText")[0].style.display = "none";
        }
      },
      amountCommas(num){
        if(num !== "" && num !== null){
        num = num.replace(/,/g, '');
        var x=parseInt(num);
        x=x.toString();
        var lastThree = x.substring(x.length-3);
        var otherNumbers = x.substring(0,x.length-3);
        if(otherNumbers != '')
            lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        }else{
          return ""
        }
      },
      async $_VueXeditable_onKeydown (event) {
        if (!this.isEditing) {
          return
        }
        if(this.type=='textarea' && this.clauseLimit && event.keyCode == 8 && event.target.value !== ""){
          this.$emit('clause-length',1001- event.target.value.length,this.index,event.target.value)
        }
        if (event.keyCode === 9) {
          //event.target.value = event.target.value.trim()
          if(this.amountFormat){
            event.target.value = await this.amountCommas(event.target.value);
          }
          this.$_VueXeditable_stopEditing(event)
          this.$_VueXeditable_valueDidChange(event.target.value);
          this.$root.$emit('changeTab'+ this.nextTabId, true);
          
        } else if (event.keyCode === 27 || event.keyCode === 13) {
          if(this.amountFormat){
            event.target.value = await this.amountCommas(event.target.value);
          }
          event.target.value = event.target.value.replace(/ +(?= )/g,'').trimStart().trimEnd();
          this.$_VueXeditable_stopEditing(event)
        }
      },
      $_VueXeditable_maybeStartEditing (value, event) {
        if (this.enableEditing && (value === 1 && !this.editOnDoubleClick) || (value === 2 && this.editOnDoubleClick)) {
          this.$_VueXeditable_startEditing(event)
        }
      },
      $_VueXeditable_startEditing (event) {
        this.isEditing = true
        var bodyWidth = screen.availWidth;
        document.getElementsByClassName("startHere")[0].style.display = "none";
        var y = event.clientY;
        //var x = event.clientX;
          y = y - 40;
          if(bodyWidth> 768){
             document.getElementById('helpText').setAttribute("style","top:"+y+"px;");
          }else{
            y = y-80;
            document.getElementById('helpText').setAttribute("style","top:"+y+"px;left:50%;transform: translateX(-50%);");
          }
          if(this.type=='textarea' && this.clauseLimit){
            document.getElementById('clause'+this.index).style.display='block'
          }
       
        setTimeout(function () {
          let inputs = Array.from(event.target.nextElementSibling.children)
          inputs.forEach(i => i.focus())
        }, 100)
      },
      async $_VueXeditable_stopEditing (event) {
        this.isEditing = false;
        if(event && event.target && event.target.value == '' && this.required){
          this.isEditing = true;
          event.target.className += ' errorpopupinput';
          if(this.required){
              event.target.placeholder = ' Required';
            }
          if(this.errorText){
          document.getElementsByClassName("helpText")[0].style.display = "block";
          document.getElementById("helpingtext").innerText = this.errorText;
          document.getElementsByClassName("helpText")[0].classList.add("vibrate");
          }
          event.target.value = event.target.value.replace(/ +(?= )/g,'').trimStart().trimEnd();
          if(this.amountFormat){
            event.target.value = await this.amountCommas(event.target.value);
          }
          this.$emit('value-did-change', event.target.value, this.name,this.attribute,this.index)
          return
        }else if(event && event.target && event.target.value == '' && !this.required){
          var odlclass1 = event.target.className;
            var newclass1 =  odlclass1.replace("errorpopupinput","");
            event.target.className = newclass1;
            if(this.helpingText || this.errorText){
              document.getElementsByClassName("helpText")[0].style.display = "none";
            }
            if(this.amountFormat){
              event.target.value = await this.amountCommas(event.target.value);
            }
            event.target.value = event.target.value.replace(/ +(?= )/g,'').trimStart().trimEnd();
            this.$emit('value-did-change', event.target.value, this.name,this.attribute,this.index)
        }else if(event && event.target && event.target.value){
          var pattern = new RegExp(this.pattern);
          if(this.pattern && !pattern.test(event.target.value)){
            this.isEditing = true;
            console.log(pattern.test(event.target.value));
            event.target.className += ' errorpopupinput';
            if(this.required){
              event.target.placeholder = ' Required';
            }
            
            if(this.errorText){
            document.getElementsByClassName("helpText")[0].style.display = "block";
            document.getElementById("helpingtext").innerText = this.errorText;
            document.getElementsByClassName("helpText")[0].classList.add("vibrate");
            }
            return
          }else{
            var odlclass = event.target.className;
            var newclass =  odlclass.replace("errorpopupinput","");
            event.target.className = newclass;
            if(this.type=='textarea' && this.clauseLimit){
              document.getElementById('clause'+this.index).style.display='none'
            }
            if(this.helpingText || this.errorText){
              document.getElementsByClassName("helpText")[0].style.display = "none";
            }
          }
        }
        if(this.type == 'text' || this.type == 'number' || this.type == 'textarea'){
          event.target.value = event.target.value.replace(/ +(?= )/g,'').trimStart().trimEnd();
          if(this.amountFormat){
            event.target.value = await this.amountCommas(event.target.value);
          }
          this.$_VueXeditable_valueDidChange(event.target.value);
        }
        // this.$emit('stop-editing', this.rawValue, this.name, event)
      },
      dateFormatter(newValue){
        if(this.type == 'date'){
          var  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          var date = new Date(newValue);
          return date.getDate() + "-" + months[date.getMonth()]   + "-" + date.getFullYear();
        }
      },
      $_VueXeditable_valueDidChange (newValue) {
        if (this.type === 'select' || this.type === 'boolean' || this.type === 'date') {
          if(this.type === 'date'){
            newValue = this.dateFormatter(newValue);
          }
          this.$_VueXeditable_stopEditing() // Needed because no events can be associated with select / option?...
        }
        if (this.type === 'boolean') {
          newValue = !this.rawValue
        }
        if (this.$_VueXeditable_hasValueChanged(newValue) || this.type === 'select') {
          // this.$emit('value-will-change', this.rawValue, this.name)

          if (this.$_VueXeditable_hasRemoteUpdate) {
            if (this.$_VueXeditable_hasValidRemote) {
              this.$_VueXeditable_sendRemoteUpdate(newValue)
                .then(() => {
                  this.$emit('value-did-change', newValue, this.name)
                })
                .catch((error) => {
                  this.$emit('value-remote-update-error', newValue, error, this.name)
                })
            } else {
              this.$emit('value-remote-error', 'Invalid Remote Update configuration.')
            }
          } else {
            this.$_VueXeditable_makeLocalUpdate(newValue);
            this.$emit('value-did-change', newValue, this.name,this.attribute,this.index)
          }
        }
      },
      $_VueXeditable_hasValueChanged (newValue) {
        return newValue !== this.rawValue
      },
      $_VueXeditable_makeLocalUpdate (newValue) {
        this.rawValue = newValue;
        return newValue;
      },
      $_VueXeditable_sendRemoteUpdate (newValue) {
        let updateValue = null
        if (this.type === 'number') {
          updateValue = parseFloat(newValue)
        } else if (this.type === 'select') {
          updateValue = (Array.isArray(newValue)) ? newValue[0] : newValue
        } else {
          updateValue = newValue.toString()
        }

        let payload = {}
        if (this.remote.resource && this.remote.resource.length) {
          let subpayload = {}
          subpayload[this.remote.key] = updateValue
          payload[this.remote.resource] = subpayload
        } else {
          payload[this.remote.key] = updateValue
        }

        return new Promise((resolve, reject) => {
          this.isRemoteUpdating = true
          axios({
            method: this.remote.method,
            url: this.remote.url,
            headers: this.remote.headers || {},
            data: payload
          })
            .then(response => {
              this.isRemoteUpdating = false
              this.$_VueXeditable_makeLocalUpdate(newValue)
              resolve(newValue, response)
            })
            .catch(error => {
              this.isRemoteUpdating = false
              reject(error)
            })
        })
      }
    }
  }
</script>

<style>
.errorpopupinput{
  border: solid 1.5px red !important;
  border-radius: 0.2vw;
}
.errorpopuplabel{
  color: red !important;
  border-radius: 0.2vw;
}
  .vue-xeditable {
    color: #222;
    line-height: 2.0em;
  }

  .vue-xeditable.enabled {
    cursor: pointer;
  }

  .vue-xeditable:hover {
    color: #666;
  }

  .vue-xeditable-value {
    white-space: pre-wrap;
    user-select: none;
  }

  .vue-xeditable-empty {
    color: #ea0002 !important;
    font-style: unset;
  }

  .vue-xeditable-control {
    width: auto;
    display: inline-block;
    margin-bottom: 1vw;
  }

  .vue-xeditable-form-control {
    display: block;
    outline: none;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }
</style>
