<template>
     <div class="container">
        <div class="thanksBox">
            <figure><img src="https://storage.googleapis.com/sqydocs/escrow/thanks-icon.svg" class="img-responsive"></figure>
            <p class="paySucess">{{title}}</p>
            <p class="rentRequest">{{description}}</p>            
        </div>
    </div>
</template>
<script>
export default {
    computed:{
        title:function(){
            if(this.$route.params.type=='bio-metric')
                return 'Biometric Appointment scheduled successfully!!';
            else
                return 'E-signing request sent successfully!!';
        },
        description:function(){
            if(this.$route.params.type=='bio-metric')
                return 'Thank you for creating a rent agreement with us. Your doorstep biometric appointment has been scheduled. We will contact you for further processing.';
            else
                return 'Thank you for creating a rent agreement with us. The agreement has been digitally stamped and sent for e-signing to all the participants. Kindly check the mail for further processing.';
        }
    },
    beforeMount(){
        sessionStorage.clear()
    }
}
</script>
<style type="text/css">
        body {
            font-family: arial, sans-serif;
        }
        
        .img-responsive {
            max-width: 100%;
        }
        
        .thanksBox {
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 50px 0;
            margin:30px auto;
            border-radius: 8px;
        }
        
        .thanksBox figure {
            margin-bottom: 45px;
        }
        
        .thanksBox .paySucess {
            font-size: 22px;
            color: #58BF93;
            margin: 0;
            font-weight: 500;
        }
        
        .thanksBox .rentRequest {
            font-size: 18px;
            line-height: 26px;
            margin: 30px 0;
            color: #333333;
            text-align: center;
            max-width: 60%;
        }
        
        .thanksBox .payAmount {
            font-size: 32px;
            font-weight: 500;
            margin: 0;
        }
        
        .thanksBox .payRefId {
            font-size: 18px;
            color: #333333;
            text-align: center;
            margin-top: 16px;
        }
        
        .thanksBox .redirect {
            color: #666666;
            font-size: 14px;
            margin: 50px 0 0 0;
        }
        
        @media screen and (max-width: 580px) {
            .thanksBox .paySucess {
                font-size: 18px;
            }
            .thanksBox .rentRequest {
                font-size: 16px;
                text-align: center;
                margin: 15px 0;
                max-width: 80%;
            }
            .thanksBox .payAmount {
                font-size: 24px;
            }
            .thanksBox .payRefId {
                font-size: 14px;
            }
            .thanksBox .redirect {
                text-align: center;
            }
        }
    </style>