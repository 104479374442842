<template>
    <div class="sumBody waterMark usSelectNone pdfDownload">
        <component
        :is="componentName"
        :readOnly="true"
        :esignData="previewData"
        :currentCity="agreementCity"
        :stamp="dummyStamp"
        />
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { defaultView,MumbaiLeaseAgreement, LeaseAgreement } from "./templates";
import { getItemFromStorage } from "@/_helpers/utility";

export default {
  data() {
    return {
      lanlord: "",
      promoCode: "",
      agreementCity: "",
      agreementName: "",
      pdfDownload: false,
      reqData: "",
      previewData: "",
      componentName: "defaultView",
      paymentUrl: null,
      couponCode: "",
      dummyStamp:false,
      notValidCoupon: false,
      validCoupon: false,
      couponApplyed:true,
      couponValue: 0,
    };
  },

  name: "agreementPrivew",
  components: {
    MumbaiLeaseAgreement,
    defaultView,
    LeaseAgreement,
  },
  computed: {
    ...mapGetters("master", { masterData: "getData" }),
    ...mapState({ state: (state) => state.agreement }),
    amount: function () {
      if (this.masterData && this.masterData["packages"]) {
        const _package = this.masterData["packages"].find(
          (p) =>
            p.CityName == this.agreementCity &&
            p.AgreementName == this.agreementName
        );
        if ((_package && (this.agreementCity == "Mumbai" || this.agreementCity == "Pune" || this.agreementCity =="Thane" || this.agreementCity =="Navi Mumbai") ) 
          ) {
          let amount = 0;
          for (var i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
            return Math.round(
              parseInt(_package.Amount) -
                parseInt(_package.DiscountAmount) +
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit) *
                        (parseInt(this.reqData.contractDuration) / 12))) +
                1000
            );
          } else {
            return Math.round(
              _package.Amount +
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit) *
                        (parseInt(this.reqData.contractDuration) / 12))) +
                1000
            );
          }
        } else {
          if ((_package && (this.agreementCity !== "Mumbai" && this.agreementCity !== "Pune" && this.agreementCity !=="Thane" && this.agreementCity !=="Navi Mumbai" ) ) 
            ){
              if(this.validCoupon){
              return _package.Amount-_package.DiscountAmount;
            }else{
              return _package.Amount;
            }
          }
        }
      }
      return "-";
    },
    getToken: function () {
      return getItemFromStorage("token");
    },
    getSource: function () {
      return getItemFromStorage("source");
    },
  },
  methods: {
    ...mapActions("agreement", ["previewAgreementInfo"]),
    ...mapActions("payment", {
      paymentAgreement: "createInvoice",
      paymentStatus1: "paymentStatus1",
    }),
    ...mapActions("coupon", { checkCoupon: "checkCoupon" }),
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getPreviewData() {
      var data = await this.previewAgreementInfo({ id: this.$route.params.id });
      this.reqData = data.data;
      if (data.paymentURL) {
        this.paymentUrl = data.paymentURL;
      }
      this.previewData = data;
      // let customClause = [];
      // for (var c = 0; c < this.previewData.data.clause.length; c++) {
      //   if (this.previewData.data.clause[c].custom) {
      //     customClause.push(this.previewData.data.clause[c]);
      //     this.previewData.data.clause.splice(c, 1);
      //     c = c - 1;
      //   }
      // }
      // this.previewData.data.customClause = customClause;
      // for(var d=0;d<customClause.length;d++){
      //     this.previewData.data.clause.splice(this.previewData.data.clause.length-1, 0, customClause[d]);
      // }
      if (data.status) {
        let pckgename = data.data.package.replace(/ /g, "");
        this.agreementCity = data.data.city;
        this.agreementName = data.data.package;
        if (
          pckgename == "RentalAgreement" ||
          pckgename == "RentalAgreement+PoliceVerification"
        ) {
          if (this.agreementCity == "Mumbai" || this.agreementCity == "Pune" ||this.agreementCity == "Thane" || this.agreementCity == "Navi Mumbai" ||this.agreementCity=="Mumbai Rural"||this.agreementCity=="Pune Rural")
            this.componentName = "MumbaiLeaseAgreement";
          else this.componentName = "LeaseAgreement";
        } else {
          this.componentName = "defaultView";
        }
      }
        for(let i=0;i<this.previewData.data.witness.length;i++){
          if(this.previewData.data.witness[i].witnessName==''||this.previewData.data.witness[i].witnessPhone==''){
            this.previewData.data.witness[i].visible=false
          }
    }
    },

    async getUnits() {
      var newReq = await this.previewNow(this.$route.params.id);
      var newData = newReq.data[0];
      for (var landlord = 0; landlord < newData.TxParties.length; landlord++) {
        if (newData.TxParties[landlord].Roles.x.RefName == "Landlord") {
          this.landlordParty.push(newData.TxParties[landlord].Party);
        }
      }
    },

  },
  async beforeMount() {
    // this.getUnits();
    if(this.$route.params.stamp == "1"){
      this.dummyStamp = true;
    }
    this.getPreviewData();
  },
  beforeCreate: function() {
        document.body.className = 'bg-white';
  },
};
</script>
<style>
body{background: #fff }
.pb-15{padding-bottom: 15px;}
.pt-0{padding-top: 0;}
.pdfDownload {
    padding-top: 20px;
    width: 795px;
    margin: auto;
    background: #fff;
}
.clipBoard .clipBox{padding: 3.5% 3.5% 3.5%;}
.clipBoard .txt {padding: 15px 0;font-size:16px;line-height: 20px;font-weight: 600;}
.stempView img{width: 100% !important;}
.clipBoard .txt {font-size: 16px;line-height: 30px;font-weight: 600;padding: 16px 0;}
.waterMark {
    background: #fff url(https://storage.googleapis.com/sqydocs/escrow/watermark.png) repeat-y center 10% !important;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
th {
    text-align: left;
}
.table-responsive{padding-top: 10px;; width: 100%;display: block;overflow: hidden;min-height: .01%;}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 4px;
    line-height: 1.42857143;
}
.pdfDownload p, .pdfDownload .orderList li {
    font-size: 16px;
    line-height: 30px;
    color: #666666;
    margin-bottom: 15px;
}
.pdfDownload .table .table{background: transparent;}
.text-center {
    text-align: center !important;
}
</style>