<template>
    <div>
        <div class="modal-main shar-modal" :class="{'active':shareUrl !== ''}">
             <div class="modal-dialog">
               <div class="modal-header"><h3></h3><em class="icon-close" @click="closeSharer"></em></div>
               <div class="modelBody">
                <h3 class="text-center pb-15 text-bold shar-heding">Agreement has been shared successfully with the user over mail.<br>
                   Alternatively, you can copy the following link and share it over <span class="whtsApp"><img src="./../../assets/images/whats-app.svg"/> WhatsApp</span> </h3>
                <div class="form-group input-group sharDiv">
                   <input type="text" name="shareUrl" v-model="shareUrl" id="urlShare" placeholder="Name" class="form-control"/>
                   <button class="btn btn-primary" @click="copyText()">Copy Link</button>
                </div>
                <p class="text-success Aurl" v-show="urlScuess">Agreement URL copied successfully!</p>
              </div>
            </div>
          </div>
          <div class="modal-main"  :class="{'active':sharingOn}" v-if="sharingOn">
              <div class="modal-dialog share-modal">
                <div class="modal-header"><h3>Share Agreement With</h3><em class="icon-close" @click="sharingClose"></em></div>
                <div class="modelBody">
                  <div class="form-group">
                    <!-- <input type="text"  
                      v-model="referenceName" pattern="[A-z ]$" placeholder="Name" required="required" class="form-control sharvalue"
                      @blur="stopEditing"
                      /> -->
                      <label for="" class="label">Name</label>
                      <select  class="form-control" v-model="referenceName" @change="sharedPartyInfo" @blur="stopEditing">
                         <option v-for="(shrdParties,indx) in sharingParties" :key="indx"  :value="shrdParties.ownerName ?shrdParties.ownerName:shrdParties.tenantName" >{{shrdParties.ownerName?shrdParties.ownerName:shrdParties.tenantName}}</option>
                      </select>
                  </div>
                  <div class="form-group">
                     <label for="" class="label">Email Id</label>
                    <input type="text" @blur="stopEditing" v-model="referenceEmail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  placeholder="Email id" class="form-control sharvalue bracket" required="required sharvalue" readonly/>
                  </div>
                  <div class="form-group">
                    <label for="" class="label">Contact Number</label>
                    <div class="group-phone bracket">
                      <div class="inputSelct" readonly>
                      <select class="form-control" readonly>
                        <option >+91</option>
                      </select>
                    </div>
                    <input type="text" maxlength="10" @blur="stopEditing" v-model="referenceNumber" pattern="^[0-9]{10}$" placeholder="Phone Number" class="form-control sharvalue " required="required sharvalue" readonly/>
                    </div>
                    
                    
                  </div>
                  <div class="form-group">
                     <label for="" class="label">City </label>
                     <input type="text"   @blur="stopEditing" v-model="referenceCity"  placeholder="City" class="form-control sharvalue bracket" required="required sharvalue" readonly/>
                    <!-- <select class="form-control sharvalue" v-model="referenceCity" required="required" @blur="stopEditing">
                      <option value="" disabled selected>Choose City</option>
                      <option v-for="(shreCity,index ) in masterData.cities" :key="index" :value="shreCity.Name">{{shreCity.Name}}</option>
                    </select> -->
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary btn-block" @click="submitSharer"><em class="icon-share"></em> Share Agreement Now</button>
                  </div>
                </div>
              </div>
          </div>
    </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
import { getItemFromStorage } from "@/_helpers/utility"
export default ({
    props:["sharingOn","previewData"],
    name:'shareAgreement',
    data(){
        return{
            referenceName: "",
            referenceEmail:"",
            referenceNumber:"",
            referenceCity:"",
            shareUrl:"",
            urlScuess:false,
            sharingParties:[]
        }
        
    },
     computed: {
        ...mapGetters("master", { masterData: "getData" }),
    },
    async beforeMount(){
      const userData = getItemFromStorage("user");
      this.userRole = userData.UserType;
    },
    mounted(){
      setInterval(() => {
            this.sharedParties();
        }, 10000);
    },
    methods:{
        ...mapActions('agreement',["shareRentalAgreemnt"]),
        copyText(){
            var copyText = document.getElementById("urlShare");
            copyText.select();
            document.execCommand("copy");
            this.urlScuess = true;
        },
        changes(newVal){
          let presentOwner = false;
            for(let i =0;i<newVal.length;i++){
              let currentOwner = newVal[i].ownerName?newVal[i].ownerName :newVal[i].tenantName;
              if(currentOwner == this.referenceName){
                this.referenceName=newVal[i].ownerName ? newVal[i].ownerName :newVal[i].tenantName;
                this.referenceEmail=newVal[i].ownerEmail ?newVal[i].ownerEmail:newVal[i].tenantEmail;
                this.referenceNumber=newVal[i].ownerMobile ?newVal[i].ownerMobile:newVal[i].tenantMobile;
                this.referenceCity=newVal[i].ownerCity ?newVal[i].ownerCity:newVal[i].tenantCity; 
                presentOwner= true;
                break;
              }
            }
            if(!presentOwner){
              this.referenceName=newVal[0].ownerName ? newVal[0].ownerName :newVal[0].tenantName;
              this.referenceEmail=newVal[0].ownerEmail ?newVal[0].ownerEmail:newVal[0].tenantEmail;
              this.referenceNumber=newVal[0].ownerMobile ?newVal[0].ownerMobile:newVal[0].tenantMobile;
              this.referenceCity=newVal[0].ownerCity ?newVal[0].ownerCity:newVal[0].tenantCity;
            }
        },
        sharedParties(){
          let allParties=[];
          const details = getItemFromStorage("agreementData");
          const userData = getItemFromStorage("user");
          if(details && details.landlord && details.landlord.length>0){
          for(let i=0;i<details.landlord.length;i++){
            if(details.landlord[i].ownerName!=="" && parseInt(details.landlord[i].ownerMobile)!==userData.Mobile){
              allParties.push(details.landlord[i])
              }
            }
             for(let i=0;i<details.tenant.length;i++){    
            if(details.tenant[i].tenantName!==''){
              allParties.push(details.tenant[i])
               }
             }
             if(allParties.length>0){
             this.changes(allParties);
             }
          this.sharingParties = allParties;
          return allParties;
          }
        },
        stopEditing(event) {
            if (event && event.target && event.target.pattern) {
                var pattern = new RegExp(event.target.pattern)
                var check = pattern.test(event.target.value);
                console.log(check);
                if (!check) {
                    event.target.value = "";
                    event.target.placeholder = "Please Enter a Valid Value";
                    event.target.classList.add("properyInfoError");
                } else {
                    event.target.classList.remove("properyInfoError");
                }
            } else {
                const selectV = event.target.value;
                if (selectV) {
                    event.target.classList.remove("properyInfoError");
                } else {
                    event.target.classList.add("properyInfoError");
                }
            }
        },
        sharingClose(){
          this.$root.$emit('sharingOnNow',!this.sharingOn);
        },
        sharedPartyInfo(){
          const showData = this.sharingParties;
          for(var k=0;k<showData.length;k++){
            let name = showData[k].ownerName?showData[k].ownerName:showData[k].tenantName;
            if(name == this.referenceName){
              this.referenceEmail = showData[k].ownerEmail?showData[k].ownerEmail:showData[k].tenantEmail
              this.referenceNumber = showData[k].ownerMobile?showData[k].ownerMobile:showData[k].tenantMobile
              this.referenceCity=showData[k].ownerCity? showData[k].ownerCity:showData[k].tenantCity
            }
          }
        },
        async submitSharer(){
          var sharemoda = document.getElementsByClassName('sharvalue');
          for (let i = 0; i < sharemoda.length; i++) {
              if (sharemoda[i].getAttribute('required') == "required" && sharemoda[i].value == "") {
                  console.log(sharemoda[i].getAttribute('required'), sharemoda[i].value);
                  sharemoda[i].classList.add('properyInfoError');
              }
          }
        if(document.getElementsByClassName('properyInfoError').length <1){
          this.openModel = "loader";
          this.$root.$emit('loaderOpen',this.openModel);
          let data = {
            "name":this.referenceName,
            "email":this.referenceEmail,
            "number":this.referenceNumber,
            "city":this.referenceCity,
            "user":getItemFromStorage("user")
          }
          if("transactionId" in sessionStorage){
            data.id = getItemFromStorage("transactionId")
          }else{
            data.agreement = getItemFromStorage("agreementData");
            data.agreementServiceId = getItemFromStorage("agreementId");
          }
          const response = await this.shareRentalAgreemnt(data);
          if(response.status){
            this.shareUrl = response.url;
            this.$root.$emit('shareUrl',true);
          }
          this.openModel = "";
          this.$root.$emit('sharingOnNow',!this.sharingOn);
          this.$root.$emit('loaderOpen',this.openModel);
        }
      },
      closeSharer(){
        // this.$router.push({'path':'/my-agreement/'+this.$route.params.source+'/'+this.$route.params.token});
        window.parent.postMessage({ type: 'myAgreement' }, '*');
      }
    }
})
</script>
