<template>
   <div id="app" @click="closeCity">
     <autoTimeout></autoTimeout>
    <div class="rentelAggrement">
      <div class="container">
        <div class="aggrementBox">
          <!-- <div class="countMain">
            <ul class="countBox">
              <div v-for="(lndlrd,indx) in landlord" :key="indx">
							<li class="active" v-for="(value,name,index) in lndlrd" :key="index" :style="[checkProgress('landlord'+indx+name)?{'background-color':'#5a3f99'}:{'background-color':'white'}]"></li>
              </div>
							<li class="active" :style="[landlordSection?{'background-color':'#5a3f99'}:{'background-color':'white'}]"></li>
							<li class="active" :style="[tenantSection?{'background-color':'#5a3f99'}:{'background-color':'white'}]"></li>
							<li class="active" :style="[landlordSection?{'background-color':'#5a3f99'}:{'background-color':'white'}]"></li>
							<li class="active" :style="[landlordSection?{'background-color':'#5a3f99'}:{'background-color':'white'}]"></li>
							<li class="active"></li>
							<li class="active"></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
					</ul>
          </div> -->
          <div class="helpText" id="helpText" >
            <em class="icon-close" @click="closeHelpBox"></em>
            <!-- <h5>Helping Text</h5> -->
            <p id="helpingtext"></p>
          </div>
          <div class="heading">
            <h1>Rent Agreement</h1>
            <p>
              Create your rental agreement online in a swift and hassle free
              manner
            </p>
          </div>
          <div class="cityType" :class="{cityOpacity:this.$route.params.id}">
            <div class="city selectC">
              <div class="dropdown"  :class="{open:cityOpen}">
                <label for="">Agreement City*</label>
                <button class="btn btn-primary dropdown-toggle" @click="openMenu('city')" type="button">
                  <span class="text"
                    >
                    {{currentCity}}</span
                  ><em class="icon-arrow-down"></em>
                </button>
                <div class="dropdown-menu">
                  <div class="city-search"><em class="icon-search"></em> <input type="text" class="form-control" v-model="citySearch" placeholder="City Search"/></div>
                  <ul class="city-select-city">
                    <li v-for="(city,idx) in cityFilter" :key="idx" :value="city.Id" @click="getcity(city.Name,city.Id),cityChange($event)" :data-value='city.Id'>
                        {{city.Name}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="city lease selectC">
              <div class="dropdown" :class="{open:pkgOpen}">
                <label for="">Agreement Type*</label>
                <button class="btn btn-primary dropdown-toggle" type="button" @click="pkgeOpen()">
                  <span class="text">{{currentPackage}}</span
                  ><em class="icon-arrow-down"></em>
                </button>
                <ul class="dropdown-menu package">
                  <li v-for="(pkg,idx) in newpackages" :key="idx" @click="getPackage(pkg.AgreementName,pkg.AgreementId)">
                    <label for="">{{pkg.AgreementName}}</label>
                      <ul v-html="pkg.Description" class="selectPackage">
                      </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="lLAgg">
            <div class="pop rent1">
              <img src="../../assets/images/rent-1.png" alt="pop" />
            </div>
            <div class="pop rent2">
              <img src="../../assets/images/rent-2.png" alt="pop" />
            </div>
            <div class="pop rent3">
              <img src="../../assets/images/rent-3.png" alt="pop" />
            </div>
            <div class="pop rent4">
              <img src="../../assets/images/rent-4.png" alt="pop" />
            </div>
          </div> -->
          
          <div class="shareBox"  v-if="referrerForm && currentPackage !== 'Select Package' && userRole.toUpperCase() !== 'USER'" >
           
           <!-- <span class="share" v-if="sharehover">Please fill atleast one party to share</span>  -->
           <span>Creating Rent Agreement</span>
            <!-- <div class="btn-group">
               <label>
                  <input type="radio" name="agreementUse" value="selfUse"> Self Use
               </label>
               <label>
                  <input type="radio" name="agreementUse" value="selfUse"> Sharing
               </label>
            </div>  -->
            <button id="sharebtn" @mouseover="checkdata" data-tooltip="Please add the complete details of at least one Landlord/Tenant for sharing the agreement (You cannot share to yourself)"  class="btn btn-secondary"  @click="shareAgreement">Share Now</button>
         </div>
          <div class="modal-main shar-modal" :class="{'active':shareMore}">
             <div class="modal-dialog">
               <div class="modal-header"><h3></h3><em class="icon-close"></em></div>
               <div class="modelBody">
                <h3 class="text-center pb-15 text-bold shar-heding">This Agreement has already been shared with another person. Please, contact your agent to share another agreement. </h3>
              </div>
            </div>
          </div>
          <shareAgreement :sharingOn="sharingOn" :previewData="previewData"></shareAgreement>
          <components :is="getComponentName" :currentCity="currentCity" :previewData="previewData" :agreementId="agreementId" :agreementAmt="newAgreementAmount"/>
        </div>
      </div>
    </div>
    <overlay :openModel="openModel"></overlay>
    <!-- <div class="scrollTop" v-if="scrollTop > 200" @click="scrollTopFun">
      <em class="icon-arrow-up"></em>
    </div> -->
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
import {defaultView, MumbaiLeaseAgreement,LeaseAgreement} from './templates';
import { getItemFromStorage,setItemInStorage } from "@/_helpers/utility";
import {paymentService} from '../../_services/payment.service'
import overlay from "./templates/overlay";
import shareAgreement from './shareAgreement'
import autoTimeout from '../autoTimeout.vue'
export default {
   name:'rentalTemplate',
   data() {
      return {
         display: false,
         nullValue: null,
         cityOpen: false,
         pkgOpen:false,
         newpackages:[],
         previewData:"",
         currentCityID:'',
         citySearch:'',
         currentCity:'Select Your City',
         currentPackage:'Select Package',
         componentName: 'defaultView',
         citypkg:'',
         agreementId:'',
         scrollTop:0,
         openModel: "",
         userRole:"",
        sharingOn:false,
        referrerForm:true,
        shareMore:false,
        landlordSection:false,
        tenantSection:false,
        newAgreementAmount:''
      }
   },
   components:{MumbaiLeaseAgreement,defaultView,LeaseAgreement,overlay,shareAgreement,autoTimeout},
   
   computed: {
        ...mapGetters("master", { masterData: "getData" }),
        getComponentName()
        {
          let agreementType=this.currentPackage.replace(/ /g,"");
          if(agreementType=='RentalAgreement' || agreementType == 'RentalAgreement+PoliceVerification'){
            if(this.currentCity == 'Mumbai' || this.currentCity == 'Pune' || this.currentCity == 'Mumbai Rural' || this.currentCity == 'Thane' || this.currentCity == 'Navi Mumbai'||this.currentCity=='Pune Rural')
              return "MumbaiLeaseAgreement";
            else
              return 'LeaseAgreement'  
          }
          else
            return 'defaultView';
        },
        cityFilter(){
          if(this.citySearch){
            return this.masterData.cities.filter((item)=>{
              return item.Name.toLowerCase().startsWith(this.citySearch.toLowerCase());
            });
          }else{
            return this.masterData.cities;
          }
        }

    },
    mounted(){
      this.$root.$on('sharingOnNow',(data)=>{
        this.sharingOn = data;
      });
      this.$root.$on('loaderOpen',(data)=>{
        this.openModel = data;
      });
      // this.$root.$on('progressBar',(data)=>{
      //   // this.landlordSection = data.landlordSection;
      //   this[data.progressSection]=data.show;
      //   console.log(data.progressSection,this[data.progressSection])
      //   this[data.type] = data.checkArray;
      //   // this.tenantSection = data.tenantSection;
      // });
      this.$root.$on('shareMore',(data)=>{
        this.shareMore = data;
        this.$router.push({ 'path': '/my-agreement/' + this.$route.params.source + '/' + this.$route.params.token })
        window.parent.postMessage({ type: 'myAgreement' }, '*');
      });
    },
    async beforeMount(){
      if(this.$route.params.id){
        await this.prefillData();
        //const paymentStatus1 = await this.paymentStatus1(this.$route.params.id);
        // if(paymentStatus1){
        //     window.parent.postMessage({type:'paid'}, '*');
        // }
      }else{
        this.setAgreement();
      }
      if(this.$route.query.referrer){
        this.referrerForm = false;
      }
      const userData = getItemFromStorage("user");
      this.userRole = userData.UserType;
    },
    methods:{
      ...mapActions('agreement',["agreementInfo","previewAgreementInfo"]),
      ...mapActions('payment', {
            paymentStatus1: 'paymentStatus1'
        }),
      handleScroll() {
        this.scrollTop = window.scrollY;
        //console.log(this.scrollTop);
      },
      scrollTopFun(){
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      },
      checkProgress(tagName){
        return this[tagName];
      },
      setAgreement(){
        if ("agreementData" in sessionStorage) {
          var oldData = getItemFromStorage("agreementData");
          this.currentCity = oldData.city;
          this.currentPackage = oldData.package;
          for(var i =0;i<this.masterData.packages.length; i++){
            if(this.masterData.packages[i].CityName == this.currentCity ){
              this.newpackages.push(this.masterData.packages[i])
            }
          if(this.masterData.packages[i].ServiceTypeId == '1' && this.masterData.packages[i].CityName == this.currentCity && this.masterData.packages[i].AgreementName == this.currentPackage){
            // this.agreementId = this.masterData.packages[i].Id;
            setItemInStorage("agreementId", this.masterData.packages[i].Id);
          }
        }
        }
      },
   
    async prefillData(){
      var data = await this.previewAgreementInfo({"id":this.$route.params.id});
      if(data.status){
        this.previewData = data;
        let currentUser = getItemFromStorage("user");
        if (this.previewData.referredBy == currentUser.Id || (this.previewData.createdBy > 1 && this.previewData.createdBy !== currentUser.Id)) {
          this.shareMore = true;
          setTimeout(()=>{
            this.$router.push({ 'path': '/my-agreement/' + this.$route.params.source + '/' + this.$route.params.token })
            window.parent.postMessage({ type: 'myAgreement' }, '*');
          },4000)
        }
        if(this.previewData.referredBy !== 1){
          this.referrerForm = false;
        }
        sessionStorage.setItem("agreementData", JSON.stringify(data.data));
        // this.agreementId = data.agreementServiceId;
        setItemInStorage("agreementId", data.agreementServiceId);
        this.currentCity = data.data.city;
        this.currentPackage = data.data.package;
        for(var i =0;i<this.masterData.packages.length; i++){
            if(this.masterData.packages[i].CityName == this.currentCity ){
              this.newpackages.push(this.masterData.packages[i])
            }
        }
      }
    },
      openMenu(){
        if(!this.$route.params.id){
          this.cityOpen = !this.cityOpen;
          this.pkgOpen = false;
          document.getElementById('helpText').style.display = 'none';
        }
         
      },
      pkgeOpen(){
        if(!this.$route.params.id){
          this.pkgOpen = !this.pkgOpen;
          this.cityOpen = false
        }
        
      },
      async getcity (data,dataId){

        console.log(data,'data',dataId,'data ID' );
        
        window.parent.postMessage({ height: 700+ 'px' }, '*');
        this.cityOpen = false;
        sessionStorage.removeItem("agreementData");
        sessionStorage.removeItem("transactionId");
        // const d=getItemFromStorage("agreementData");
        this.previewData='';
        this.currentCity = data;
        this.currentPackage = 'Select Package';
        var oldData = getItemFromStorage("agreementData");
        oldData.city = this.currentCity;
        oldData.cityID = dataId;
        oldData.buildingType = "Residential";
        oldData.propertyAreaType = "Built-up area";
        oldData.propertyType = "";
        oldData.flatNo = "";
        oldData.builtUparea = "";
        oldData.buildUpunit = "Sq. Ft.";
        oldData.floorNumber = "";
        oldData.buildingName = "";
        oldData.propertyLocation = "";
        oldData.propertyAddressone = "";
        oldData.locality = "";
        oldData.propertyPinCode = "";
        oldData.package =this.currentPackage;
        setItemInStorage("agreementData", oldData);
        
      },
    async getPackage(myPackage,agreementID){
      console.log(myPackage,agreementID);
         this.pkgOpen = false
         this.currentPackage = myPackage;
         //console.log(this.currentCityID,agreementID);
        //  localStorage.clear();
        if("agreementData" in sessionStorage){
          var oldData = getItemFromStorage("agreementData");
          oldData.city = this.currentCity;
          oldData.package = myPackage;
          setItemInStorage("agreementData", oldData);
        }else{
          var data = {
            "city":this.currentCity,
            "package":myPackage
          }
         setItemInStorage("agreementData", data);
        }
        for(var i =0;i<this.masterData.packages.length; i++){
          if(this.masterData.packages[i].ServiceTypeId == '1' && this.masterData.packages[i].CityId == this.currentCityID && this.masterData.packages[i].AgreementId == agreementID){
            //console.log( this.masterData.packages[i].Id)
            // this.agreementId = this.masterData.packages[i].Id;
            setItemInStorage("agreementId", this.masterData.packages[i].Id);
          }
        }
        this.referrerForm = true;
        let amountPaylod = {
          "cityId":oldData.cityID,
          "agreementType":myPackage
        }
        let agreementApiAmount = await paymentService.agreementAmountNew(amountPaylod);
        console.log(agreementApiAmount);
        if(agreementApiAmount.status){
          this.newAgreementAmount = agreementApiAmount.data
        }
      },
      cityChange(event){
        this.citySearch = '';
        this.newpackages.length = 0;
        var cityId = event.target.value;
        this.componentName=''
        this.currentCityID = event.target.value;
        var packages = this.masterData.packages;
        var newpackages = [];
        //console.log(packages);
        this.previewData='';
        this.agreementId='';
        for(var i=0;i<packages.length;i++){
            if(packages[i].CityId == cityId){
                newpackages.push(packages[i]);
            }
        }
        this.newpackages = newpackages;
        // if(this.currentCity !="Pune" && this.currentCity !=="Mumbai"){
        //   this.currentPackage = newpackages[0].AgreementName;
        // }
      },
       checkAgreement(){
          let share=false
          var oldData = getItemFromStorage("agreementData");
          const userData = getItemFromStorage("user");
        if(oldData.city!=='Mumbai' && oldData.city!=='Pune' && oldData.city!=='Thane' && oldData.city!=="Navi Mumbai"){
              if(oldData.landlord.length>0 || oldData.landlord.tenant>0){
            if((oldData.landlord[0].ownerTitle!='' && oldData.landlord[0].ownerName!=''   && oldData.landlord[0].ownerParentTitle!=''  && oldData.landlord[0].ownerParentName!=''  && oldData.landlord[0].ownerMobile!='' && parseInt(oldData.landlord[0].ownerMobile) !== userData.Mobile && oldData.landlord[0].ownerEmail!='' &&  oldData.landlord[0].ownerAddress1!=''&& oldData.landlord[0].ownerAddress2!='' && oldData.landlord[0].ownerCity!=''  && oldData.landlord[0].ownerPin!='')
            || (oldData.tenant[0].tenantTitle!='' && oldData.tenant[0].tenantName!=''  && oldData.tenant[0].tenantParentTitle!='' && oldData.tenant[0].tenantParentName!='' && oldData.tenant[0].tenantMobile!=''  && oldData.tenant[0].tenantEmail!='' &&  oldData.tenant[0].tenantAddress1!='' && oldData.tenant[0].tenantAddress2!='' && oldData.tenant[0].tenantPAN!='' && oldData.tenant[0].tenantUID!='' && oldData.tenant[0].tenantCity!='' && oldData.tenant[0].tenantPin!='' ))
            { 
              share=true  
            }}
          } else{
            if(oldData.landlord.length>0 || oldData.landlord.tenant>0){
              if((oldData.landlord[0].ownerName!='' && oldData.landlord[0].ownerAge!='' && oldData.landlord[0].ownerGender!='' && oldData.landlord[0].ownerMobile!=''  && parseInt(oldData.landlord[0].ownerMobile) !== userData.Mobile  && oldData.landlord[0].ownerEmail!='' &&  oldData.landlord[0].ownerAddress1!=''&& oldData.landlord[0].ownerAddress2!='' && oldData.landlord[0].ownerCity!=''  && oldData.landlord[0].ownerPin!='')
              || (oldData.tenant[0].tenantName!='' && oldData.tenant[0].tenantAge!='' && oldData.tenant[0].tenantGender!='' && oldData.tenant[0].tenantMobile!=''  && oldData.tenant[0].tenantEmail!='' &&  oldData.tenant[0].tenantAddress1!='' && oldData.tenant[0].tenantAddress2!='' && oldData.tenant[0].tenantPAN!='' && oldData.tenant[0].tenantUID!='' && oldData.tenant[0].tenantCity!='' && oldData.tenant[0].tenantPin!='' ))
              { 
                share=true  
              }
            } }
            return share
        },
      shareAgreement(){
         const check=this.checkAgreement()
         console.log(check);
         if(check==true){{
           this.sharingOn=!this.sharingOn;
         }}
      },
      checkdata(){
        const check=this.checkAgreement()
        if(check==true){
            document.getElementById('sharebtn').removeAttribute('data-tooltip')
        }
      },
      closeCity(){
        // let container = document.getElementsByClassName('dropdown')[0]
        // if(!container.is(e.target) && container.has(e.target).length === 0){
        //     container.classList.remove('open');
        // }
        //document.getElementsByClassName('dropdown')[0].classList.remove("open")
      },
    
      closeHelpBox() {
          document.getElementsByClassName('helpText')[0].style.display = "none";
      },
      
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>