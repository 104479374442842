<template>
    <div>
        <component :is="componentName"></component>
    </div>
</template>


<script>
import { mapActions,mapGetters } from "vuex";
import esign from './eSign'
import biometric from './bioMetric'
// import {getItemFromStorage} from '../../_helpers/utility';
export default {
    data(){
        return {
            reqData:{},
            previewData:"",
            fileData:"",
            agreementCity:'',
            agreementName:'',
        }
    },
    components:{
        esign,biometric
    },
    computed: {
         ...mapGetters("master", { masterData: "getData" }),
        componentName:function()
        {
           if(this.masterData && this.masterData['packages']){
                const _package= this.masterData['packages'].find(p=>p.CityName==this.agreementCity && p.AgreementName==this.agreementName);
                if(_package && _package.Isrequired_stamp==false){
                    window.parent.postMessage({type:'myAgreement'}, '*');
                    return '';
                }else{
                    console.log('esign')
                    return 'esign';
                }
            }else{
             return '';
            }
        },
    },
    methods:{
        ...mapActions('agreement',["previewAgreementInfo"]),
        ...mapActions('payment', {
            paymentStatus: 'paymentStatus',
        }),
        // ...mapActions('payment',['updatePaymentCreator']),
        async getPreviewData(){
            var data = await this.previewAgreementInfo({"id":this.$route.params.id});
            this.agreementCity = data.data.city;
            this.agreementName = data.data.package;       
        },
        // async paymentCreator(){
        //     const user=getItemFromStorage('userInfo')
        //     const data={
        //         "paymentRefNo":this.$route.params.id,
        //         "id":user.Id
        //     }
        //     console.log(data);
        //  const response= await this.updatePaymentCreator(data);
        //  console.log(response)
        // }
        
    },
    beforeMount(){
        this.getPreviewData();
        this.paymentStatus(this.$route.params.id);
        // this.paymentCreator(); 
    },
}
</script>