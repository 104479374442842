<template>
    <div class="modal-main active">
        <div class="modal-dialog BiometricDetails" id="BiometricDetails" >
            <div class="modal-header">
              <h3>Biometric Details</h3>
              <!-- <em class="icon-close" @click="closeBioMetric"></em> -->
            </div>
            <div class="modelBody">
              <div class="Biometricbody-box">
                <div class="Biometricbody">
                    <div class="biometricTab">
                      <button class="btn" :class="{'active':boxone==1}" v-on:click="boxone=1">Parties will travel to vendor location</button>
                      <button class="btn" :class="{'active':boxone==2}"  v-on:click="boxone=2">Parties need doorstep biometric</button>
                    </div>
                    <div class="VenderLocation" v-if="boxone == 1">
                      <div class="venderNote">
                        <ul>
                          <li>All the Parties need to visit the selected address at the scheduled time</li>
                          <li>Please select the location as per you convenience</li>
                        </ul>
                        <div class="form-group selectCity">
                          <label>Select Locality</label>
                            <select class="form-control" v-model="selectedLocality" @change="addFilter(selectedLocality)">
                              <option disabled selected value="">Select</option>
                              <option v-for="(local,index) in localitiesShow" :key="index" :value="local.name">{{local.name}}</option>
                          </select>
                        </div>
                        <div class="addressBox">
                          
                          <div class="addressOne" v-for="(add, idx) in addressShow" :key="idx">
                            <h4>Address {{idx+1}}</h4>
                            <p>{{add.address}} {{add.pinCode}}</p>
                            <div class="btnBox"><button class="btn" @click="selectAddress(add.id)" :class="{ active : active_el == add.id}">Select</button></div>
                          </div>
                          <!-- <div class="addressOne">
                            <h4>Address 1</h4>
                            <p>Unit No. A/401, A/402, A/403 B/406, 4th Floor, Wing A & B, Rustomjee Central Park Chakala Andheri (E), Mumbai - 400069</p>
                            <div class="btnBox"><button class="btn">Select</button></div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="VenderLocation Partiesdoorstep" v-if="boxone == 2">
                      <div class="venderNote">
                        <ul>
                          <li>Doorstep Biometric Supported only in {{BiocityList.map(e => e.name).join(", ")}}
                             <!-- <span v-for="(city,index ) in BiocityList" :key="index">
                            {{city.name}},</span> -->
                            </li>
                          <li>Pricing will increase with increase in no of Doorstep Biometric</li>
                        </ul>
                      </div>
                      <div class="">
                        <div class="col-md-6 col-lg-6 col-xm-12">
                          <h5>Add Biometric Address</h5>
                          <div class="form-group addBioMeteric">
                              <label>Name</label>
                              <div style="width:100%">
                                <input required="required" @blur="stopEditing" type="text" @focus="addressError = false"  v-model="selectParty" placeholder="Name" class="form-control addCheck" />
                                <!-- <select class="form-control addCheck" @change="changes($event)" v-model="selectParty" required="required">
                                  <option selected value="" disabled>Select</option>
                                  <option v-for="(party,indx) in allparty" :key="indx" :index="indx" :value="party.ownerName?party.ownerName:party.ownerPoaName?party.ownerPoaName:party.tenantName?party.tenantName:party.witnessName">{{party.ownerName?party.ownerName+" - Landlord":party.ownerPoaName?party.ownerPoaName+" - POA":party.tenantName?party.tenantName+" - Tenant":party.witnessName+" - Witness"}}</option>
                                </select> -->
                                <div v-if="addressError" class="addresserror">Current address not supported, Add new one</div>
                              </div>
                          </div>
                            <div class="form-group addBioMeteric">
                              <label>Contact No</label>
                              <div class="group-phone w-100">
                                <div class="inputSelct">
                                  <select class="form-control" v-model="countryCode">
                                    <option value="+91">+91</option>
                                  </select>
                                </div>
                                <input required="required" @keypress="isIndianMobile($event)" @blur="stopEditing" type="number" @focus="addressError = false"  v-model="BiocontactNo" name="BiocontactNo" placeholder="Contact No" class="form-control addCheck" maxlength="10"/>
                              </div>
                              
                          </div>
                          <div class="form-group addBioMeteric">
                              <label>Full Address</label>
                              <textarea required="required"  name="Bioaddress" placeholder="Enter address" v-model="Bioaddress" @focus="addressError = false" class="form-control addCheck"></textarea>
                          </div>
                          <div class="form-group addBioMeteric">
                              <label>City</label>
                              <select class="form-control addCheck"  v-model="Biocity" name="Biocity" @click="addressError = false" @change="getCityID" required="required">
                                <option value="" selected>Select City</option>
                                <option v-for="(bioCity, indx) in BiocityList" :key="indx"  >{{bioCity.name}}</option>
                              </select>
                          </div>
                          <div class="form-group addBioMeteric">
                              <label>Pin Code</label>
                              <input type="number"  @keypress="isIndianPincode($event)" @blur="stopEditing" name="BiopinCode" v-model="BiopinCode" @focus="addressError = false" placeholder="Enter Pincode" class="form-control addCheck" required="required"/>
                          </div>
                          <div class="form-group addBioMeteric">
                              <label></label>
                              <button class="btn btn-secondary btn-block" @click="addAddress()" v-if="!updateAddress">Add Biometric</button>
                              <button class="btn btn-secondary btn-block" @click="updateAdd(editBioMetric)" v-if="updateAddress">Update Biometric</button>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xm-12">
                          <h5>Biometric Address List</h5>
                          <div v-if="partyExist">
                               <h3 style="color:#28a745">No Biometric addresses added!! </h3>
                          </div>
                          <div class="addlist">
                            <div v-for="(visit,index) in partiesAddress" :key="index">
                                <div class="bioAddresList" v-if="visit.Status == 1">
                                  <h6>Biometric Address {{index+1}} 
                                    <span class="addressAction">
                                      <span @click="editBioAddress(index)"><em class="icon-edit"></em> Edit</span>
                                      <span @click="removeBioAddress(index)"><em class="icon-delete"></em> Remove</span>
                                    </span>
                                  </h6>
                                  <div class="addressDetails">
                                    <p>
                                      <strong class="text-bold">{{visit.name}}</strong><br>
                                      {{visit.address}},{{visit.city}} ,{{visit.pinCode}}<br>
                                      Phone number :{{visit.countryCode}}-{{visit.phoneNo}}
                                    </p>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
             <div class="modal-footer">
                <div class="BiometricFooter">
                  <p>
                    <!-- &#8377; {{agreementAmount}} +  -->
                    <span v-if="boxone == 1">&#8377;{{ venderLocationAmount }} </span>
                     <span v-else>&#8377;{{ doorstepAmount }} </span>
                     <span v-if="policeVerification"> Including Police Verification </span>
                    <span> + Registration and Stamp duty Charge</span>
                  </p>
                  <button class="btn btn-primary" @click="confirmPay" v-if="(!partyExist && boxone == 2) || (active_el !== '' && boxone==1)">Confirm & Pay</button>
                </div>
             </div>
          </div>
    </div>
</template>
<script>
import config from '../../../../config.json'
import {mapActions} from "vuex";
import { getItemFromStorage} from "@/_helpers/utility";
export default ({
    name:'bioMateric',
    props:["cityId","agreementAmount","reqData","masterData","TransactionId","bioMetricIndex","apiAmount"],
    data(){
      return{
        boxone:1,
        selectParty:'',
        editBioMetric:'',
        localitiesShow:[],
        addressShow:[],
        selectedLocality:"",
        active_el:'',
        allparty:[],
        BiocontactNo:"",
        Bioaddress:"",
        Biocity:"",
        countryCode:"+91",
        BiocityList:[],
        BiopinCode:"",
        partiesAddress:[],
        cityChangeID:this.cityId,
        updateAddress:false,
        status:1,
        totalAmount:'',
        addressError:false,
        policeVerification:false
      }
    },
  computed:{
    partyExist:function(){
     let check= this.checkPartyExist();
     return check;
    },
   
    venderLocationAmount:function(){
      let amount = 0;
      if(!this.apiAmount || !this.apiAmount.data)return amount;
      if(this.reqData.package === "Rental Agreement + Police Verification" && this.apiAmount.data && this.apiAmount.data.cityId !== 3488 && this.apiAmount.data.cityId !== 27716){
        amount = parseInt( amount + this.apiAmount.data.policeVerificationCharges );
        // amount = parseInt( amount + config["policeVerification"+this.reqData.city]);
        this.isPoliceVerification();
      }else if(this.reqData.package == "Rental Agreement + Police Verification" && this.apiAmount.data && (this.apiAmount.data.cityId == 3488 || this.apiAmount.data.cityId == 27716)){
        this.isPoliceVerification();
      }
      amount = parseInt(amount + this.apiAmount.data.serviceCharges);
      return amount;
    },
    doorstepAmount:function(){
      let count = 0;
      let amount = 0;    
      if(!this.apiAmount || !this.apiAmount.data)return amount;
      for(let i=0;i<this.partiesAddress.length;i++){
        if(this.partiesAddress[i].Status == 1){
          count = count + 1;
        }
      }
      for(let j=1;j<count +1;j++){
        if(config[j] && j ==1){
          amount = amount + this.apiAmount.data.serviceCharges + this.apiAmount.data.biometricCharges;
        }else if(j==2){
          amount = amount + this.apiAmount.data.biometricCharges
        }else{
          amount = amount + config['more']
        }
      }
      if(this.reqData.package == "Rental Agreement + Police Verification" && this.apiAmount.data && this.apiAmount.data.cityId !== 3488 && this.apiAmount.data.cityId !== 27716){
        amount = parseInt(amount + this.apiAmount.data.policeVerificationCharges);
        // amount = parseInt(amount + config["policeVerification"+this.reqData.city]);
        this.isPoliceVerification();
      }else if( this.reqData.package == "Rental Agreement + Police Verification" && this.apiAmount.data && (this.apiAmount.data.cityId == 3488 || this.apiAmount.data.cityId == 27716)){
        this.isPoliceVerification();
      }
      return amount;
    },
  },
  
  methods:{
    ...mapActions('bioMetric',["getVendorsLocality","updateBioMetric","getBioMetricAddress","sendBioMetricAddress"]),
    closeBioMetric(){
      this.$root.$emit('isBioMetric',false);
    },
    isPoliceVerification(){
      this.policeVerification = true;
    },
    removeError(e){
      if(e.target.length>0){
        this.addressError=false
      }

    },
    
   
    checkPartyExist(){
     let arr=this.partiesAddress;
     if(arr.length==0){
       return true
     }else{
       let status0Count=true;
       for(let i=0;i<arr.length;i++){
         if(arr[i].Status == 1){
           status0Count = false;
           break;
         }
       }
       return status0Count;
     }
    },
    async getLocalities(){
     const data = {
        cityId:this.cityId
      }
      const Localities = await this.getVendorsLocality(data);
      this.localitiesShow = Localities.data;
      this.BiocityList = Localities.biometricLocalities;
      return Localities.data;
    },
    addFilter(cityName){
      for(let i = 0; i<this.localitiesShow.length; i++){
        if(this.localitiesShow[i].name == cityName){
          this.addressShow = this.localitiesShow[i].supporting_addresses;
        }
      }
    },
    getCityID(){
      let cityes = this.masterData.cities
      for(let i=0;i<cityes.length;i++){
        if(cityes[i].Name == this.Biocity){
          this.cityChangeID = cityes[i].Id;
        }
      }
    },
    editBioAddress(id){
      this.editBioMetric = id;
      this.updateAddress = true;
      this.selectParty =  this.partiesAddress[id].name;
      this.BiocontactNo = this.partiesAddress[id].phoneNo;
      this.Bioaddress = this.partiesAddress[id].address;
      this.Biocity = this.partiesAddress[id].city;
      this.BiopinCode = this.partiesAddress[id].pinCode;
      this.cityChangeID = this.partiesAddress[id].cityId ;
      this.status = this.partiesAddress[id].status;
    },
    isIndianMobile(event){
             let char = String.fromCharCode(event.keyCode);  // Get the character
            if(/^[0-9]+$/.test(char)){
            if(event.target.value.length<10){
                return true;
             }else{
                event.preventDefault(); 
                return false
               }
        } 
        else {event.preventDefault(); return false}
        },
        isIndianPincode(event){
             let char = String.fromCharCode(event.keyCode);  // Get the character
            if(/^[0-9]+$/.test(char)){
            if(event.target.value.length<6){
                return true;
             }else{
                event.preventDefault(); 
                return false
               }
        } 
        else {event.preventDefault(); return false}
        },
      stopEditing(event) {
        if (event && event.target && event.target.pattern) {
            var pattern = new RegExp(event.target.pattern)
            var check = pattern.test(event.target.value);
            if (!check) {
                event.target.value = "";
                event.target.placeholder = "Please Enter a Valid Value";
                event.target.classList.add("properyInfoError");
            } else {
                event.target.classList.remove("properyInfoError");
            }
        } else {
            const selectV = event.target.value;
            if (selectV) {
                event.target.classList.remove("properyInfoError");
            } else {
                event.target.classList.add("properyInfoError");
            }
        }
    },
    updateAdd(id){
      this.partiesAddress[id].name = this.selectParty;
      this.partiesAddress[id].phoneNo = this.BiocontactNo;
      this.partiesAddress[id].address = this.Bioaddress;
      this.partiesAddress[id].city = this.Biocity;
      this.partiesAddress[id].cityId = this.cityChangeID;
      this.partiesAddress[id].pinCode = this.BiopinCode;
      this.partiesAddress[id].status =1;
      let cityes = this.masterData.cities
      for(let i=0;i<cityes.length;i++){
        if(cityes[i].Name == this.Biocity){
          this.partiesAddress[id].cityId = cityes[i].Id;
        }
      }
      this.updateAddress = false;
      this.selectParty = '',
      this.BiocontactNo = '',
      this.Bioaddress = '',
      this.Biocity = '',
      this.BiopinCode = ''
    },
    removeBioAddress(id){
      if(this.partiesAddress[id].id){
        this.partiesAddress[id].Status = 0; 

        const element= this.partiesAddress[id];
        for(let i=id;i<this.partiesAddress.length;i++){
          this.partiesAddress[i]=this.partiesAddress[i+1];
        }
        this.partiesAddress[this.partiesAddress.length-1]=element;

      }else{
        this.partiesAddress.splice(id, 1);
      }
      
    },
    addAddress(){
      let addInfo = document.getElementsByClassName('addCheck');
      for(let l =0;l<addInfo.length; l++){
        if (addInfo[l].getAttribute('required') == "required" && addInfo[l].value == "") {
            addInfo[l].classList.add('properyInfoError');
        } else {
            addInfo[l].classList.remove('properyInfoError');
            this.prtybtnActive = false;
        }
      }
      if (document.getElementsByClassName('properyInfoError').length < 1){
        let usierID = getItemFromStorage('user');
        let nodeletedAddress=true
          for(let i=0;i<this.partiesAddress.length;i++){
               if(this.partiesAddress[i].Status==0){
                  nodeletedAddress=false;
                  this.partiesAddress.splice(i,0,{
                    "name":this.selectParty,
                    "countryCode":this.countryCode,
                    "phoneNo":this.BiocontactNo,
                    "address": this.Bioaddress,
                    "pinCode":this.BiopinCode,
                    "agreementInfoId":this.TransactionId,
                    "Status":1,
                    "createdBy":usierID.Id,
                    "city":this.Biocity,
                  })
                  break;
               }
          }
         if(nodeletedAddress){
          this.partiesAddress.push({
          "name":this.selectParty,
          "countryCode":this.countryCode,
          "phoneNo":this.BiocontactNo,
          "address": this.Bioaddress,
          "pinCode":this.BiopinCode,
          "agreementInfoId":this.TransactionId,
          "Status":1,
          "createdBy":usierID.Id,
          "city":this.Biocity,
        });
         }
        this.selectParty = '',
        this.BiocontactNo = '',
        this.Bioaddress = '',
        this.Biocity = '',
        this.BiopinCode = ''
      }
      
    },
    selectAddress(id){
      // alert(agreID);
      this.active_el = id;
    },
    changes(event){
      let currentParty = event.target.selectedIndex;
      let BiocityCheck = this.allparty[currentParty - 1].ownerCity?this.allparty[currentParty - 1].ownerCity:this.allparty[currentParty - 1].tenantCity?this.allparty[currentParty - 1].tenantCity:this.allparty[currentParty - 1].witnessCity;
      BiocityCheck = BiocityCheck.toUpperCase();
      let citycheck = false;
      for(let i=0;i<this.BiocityList.length;i++){
        if(this.BiocityList[i].name.toUpperCase() == BiocityCheck){
          citycheck = true;
        }
      }
      if(citycheck){
        this.addressError=false
        this.BiocontactNo = this.allparty[currentParty - 1].ownerMobile?this.allparty[currentParty - 1].ownerMobile:this.allparty[currentParty - 1].ownerPoaMobile?this.allparty[currentParty - 1].ownerPoaMobile:this.allparty[currentParty - 1].tenantMobile?this.allparty[currentParty - 1].tenantMobile:this.allparty[currentParty - 1].witnessPhone;
        this.Bioaddress = this.allparty[currentParty - 1].ownerAddress1?this.allparty[currentParty - 1].ownerAddress1+this.allparty[currentParty - 1].ownerAddress2:this.allparty[currentParty - 1].tenantAddress1?this.allparty[currentParty - 1].tenantAddress1+ ' ' +this.allparty[currentParty - 1].tenantAddress2:this.allparty[currentParty - 1].witnessAddress1+ ' ' +this.allparty[currentParty - 1].witnessAddress2;
        this.BiopinCode = this.allparty[currentParty - 1].ownerPin?this.allparty[currentParty - 1].ownerPin:this.allparty[currentParty - 1].ownerPoaPin?this.allparty[currentParty - 1].ownerPoaPin:this.allparty[currentParty - 1].tenantPin?this.allparty[currentParty - 1].tenantPin:this.allparty[currentParty - 1].witnessPin;
        this.Biocity = this.allparty[currentParty - 1].ownerCity?this.allparty[currentParty - 1].ownerCity:this.allparty[currentParty - 1].tenantCity?this.allparty[currentParty - 1].tenantCity:this.allparty[currentParty - 1].witnessCity;
      }
      else{
        this.addressError=true
        this.BiocontactNo='',
        this.Bioaddress='',
        this.BiopinCode='',
        this.Biocity =''
      }

      
    },
    doorStepParty(){
      let allPart=[];
      const partyName = this.reqData;
      if(partyName){
        for(let i =0; i<partyName.landlord.length;i++){
          if(partyName.landlord[i].ownerName !==""){
            allPart.push(partyName.landlord[i]);   
          }
          if(partyName.landlord[i].ownerPOA.length>0 && partyName.landlord[i].ownerPOA[0].ownerPoaName !==''){
            allPart.push(partyName.landlord[i].ownerPOA[0]);        
          }
        }
        for(let i=0;i<partyName.tenant.length;i++){  
          if(partyName.tenant[i].tenantName!==''){
            allPart.push(partyName.tenant[i]);
          }
        }
        for(let i=0;i<partyName.witness.length;i++){  
          if(partyName.witness[i].witnessName!==''){
              allPart.push(partyName.witness[i]);
            }
        }
        
        for(let i = 0;i<this.BiocityList.length;i++){
          if(partyName.city == this.BiocityList[i]){
            this.Biocity = partyName.city;
          }
        }
        this.allparty = allPart;
        return allPart;
      }

     
    },
    async getBioMetric(){
      var bioDetails = await this.getBioMetricAddress({ agreementId: this.$route.params.id });
      
      if(bioDetails.bioMetricType){

        this.boxone = 1;
        this.active_el = bioDetails.data.id;
        for(let i = 0; i<this.localitiesShow.length; i++){
          if(this.localitiesShow[i].id == bioDetails.data.localityId){
            this.selectedLocality = this.localitiesShow[i].name;
          }
        }
        
        this.addFilter(this.selectedLocality)
        this.$root.$emit("bioMatericAmount",{"amount":this.venderLocationAmount,"biometricTitle":"WalkIn Biometric"});
      }else if(!bioDetails.bioMetricType){
        this.boxone =2;
        const partyData =[];
        for(let k = 0;k<bioDetails.data.length;k++){
          const partAdd = {
            Status:bioDetails.data[k].Status,
            address:bioDetails.data[k].address,
            agreementInfoId:bioDetails.data[k].agreementInfoId,
            countryCode:bioDetails.data[k].countryCode,
            createdBy:bioDetails.data[k].createdBy,
            id:bioDetails.data[k].id,
            name:bioDetails.data[k].name,
            phoneNo:bioDetails.data[k].phoneNo,
            pinCode:bioDetails.data[k].pinCode,
            city:bioDetails.data[k].city
          }
          partyData.push(partAdd);
        }
          
          this.partiesAddress = partyData;
          if(this.bioMetricIndex){
            this.editBioAddress(this.bioMetricIndex-1)
          }
          this.$root.$emit("bioMatericAmount",{"amount":this.doorstepAmount,"biometricTitle": this.partiesAddress.length + " Doorstep Biometric"});
      }
      
      
    },
    async confirmPay(){
      if(this.boxone == 1){
        this.$root.$emit("openModel","loader");
        let agreID = this.$route.params.id;
        const bioAddress = {
          addressId:this.active_el,
          agreementId:agreID
        }
        let walkInAddress = '';
        for(let z=0;z<this.addressShow.length;z++){
          if(this.active_el == this.addressShow[z].id){
            walkInAddress = this.addressShow[z];
          }
        }
        await this.updateBioMetric(bioAddress);
        this.$root.$emit("isBioMetric",false);
        this.$root.$emit("openModel","");
        this.$root.$emit("bioMatericAmount",{"partiesAddress":walkInAddress,"amount":this.venderLocationAmount,"biometricTitle":"WalkIn Biometric"});
      }else if(this.boxone == 2){
        this.$root.$emit("openModel","loader");
        
        const data = {
          partiesAddresses:this.partiesAddress
        }
        
        await this.sendBioMetricAddress(data);
        this.$root.$emit("isBioMetric",false);
        this.$root.$emit("openModel","");
        this.$root.$emit("bioMatericAmount",{"partiesAddress":this.partiesAddress,"amount":this.doorstepAmount,"biometricTitle": this.partiesAddress.length + " Doorstep Biometric"});
        
        
      }
    }
  },
  async mounted(){
    await this.getLocalities();
    await this.getBioMetric();
    await this.doorStepParty();
  }
})
</script>
