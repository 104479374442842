<template>
  <div id="app">
    <div class="clipBoard">
      <div class="heading" v-if="!readOnly">
        <figure>
          <img src="../../../assets/images/aggrement-clip.png" alt="clip" class="img-responsive" />
        </figure>
        <h2>Leave & Licence Agreement</h2>
        <p class="subclip">
          This Leave & Licence Agreement (Agreement) is made and executed at
          <strong>{{currentCity}}.</strong>
        </p>
      </div>
      <div class="priveHeading text-center" v-if="readOnly">
        <h2>Leave & Licence Agreement</h2>
        <p class="subclip">
          This Leave & Licence Agreement (Agreement) is made and executed at
          <strong>{{currentCity}}.</strong>
        </p>
      </div>
      <div class="clipBox" v-bind:class="{'box-shadow':!readOnly}" id="clipBox">
        <!-- <div class="txt text-center">BETWEEN</div> -->
        
        <ol class="orderList mumbaiList">
          <li><p class="PaymentofDeposit">Agreement Duration(In Months): </p>
          <select class="form-control mumbaiValid" :class="{'properyInfoError':reqData.contractDuration==''&&finallySubmitting}" v-model="reqData.contractDuration" @change="updateRentAmount();updateIncrementMonths()">
             <option value="" selected disabled>Select</option>
            <option v-for="(option,indx) in agreementDuration" :key="indx">{{option}}</option>
          </select>
          <!-- <vue-xeditable
            type="select"
            name="contractDuration"
            attribute="contractDuration"
            placeholder="Agreement Duration"
            :value="reqData.contractDuration"
            :options="agreementDuration"
            :required="true"
            :finallySubmitting="finallySubmitting"
            :currentTabId="'agreementDuration'"
            :nextTabId="'agreementstartDate'"
            @start-editing="sendStartEditingEvent"
            @stop-editing="sendStopEditingEvent"
            @value-will-change="sendValueWillChangeEvent"
            @value-did-change="sendValueDidChangeEvent"
            v-if="!readOnly"
          />
          <span v-else class="vue-xeditable-value">{{reqData.contractDuration}}</span></li> -->
          <li>
            <p class="PaymentofDeposit">Monthly Rent Amount (in Rs)</p>
            <input type="text" v-model="reqData.initialRentAmount" @input="updateRentAmount" :class="{'properyInfoError':reqData.initialRentAmount==''&&finallySubmitting}" @keypress="isNumeric" class="form-control mumbaiValid"/>
          </li>
          <li>
            <p class="PaymentofDeposit">Security Deposit Amount (in Rs) </p>
            <input type="text" :class="{'properyInfoError':reqData.refundableDeposit==''&&finallySubmitting}" v-model="reqData.refundableDeposit" @input="updateRentAmount" @keypress="isNumeric" class="form-control mumbaiValid"/>
          </li>
          <li class="increementMonth">
            <p class="PaymentofDeposit">Escalation / Increment</p>
            <div class="inputGroup">
                <input type="text" v-model="reqData.incrementPercent" @input="updateRentAmount" @keypress="isNumeric" class="form-control mumbaiValid"/>
              <p class="PaymentofDeposit">% Every</p>
              <select class="form-control properyInfo" :class="{'properyInfoError':reqData.incrEveryMonth==''&&finallySubmitting}"  v-model="reqData.incrEveryMonth"  @change="updateRentAmount">
                <option value="" selected disabled>Select</option>
              <option v-for="(option,indx) in lockInOptions" :key="indx+'a'">{{option}}</option>
            </select>
            <p class="PaymentofDeposit">Months</p>
            </div>
          </li>
          <li>
            
            
          </li>
          <!-- <li>
            <strong class="PaymentofDeposit">Security Deposit Amount: </strong>
            <vue-xeditable
              type="text"
              name="refundableDeposit"
              attribute="refundableDeposit"
              :value.sync="reqData.refundableDeposit"
              :enable-editing="true"
              :required="true"
              :finallySubmitting="finallySubmitting"
              :amountFormat="true"
              validateText="isNumeric"
              placeholder="Refundable Deposit"
              :currentTabId="'refundableDeposit'"
              @start-editing="sendStartEditingEvent"
              @stop-editing="sendStopEditingEvent"
              @value-will-change="sendValueWillChangeEvent"
              @value-did-change="sendValueDidChangeEvent"
              v-if="!readOnly"
            />
            <span v-else class="vue-xeditable-value">{{reqData.refundableDeposit}}</span>
          </li> -->
        </ol>
        <ol class="orderList mumbaiList">
              <li><p class="PaymentofDeposit">Rent Amount: </p></li>
              <li v-for="(rentamount, index) in reqData.rentamount" :key="index + 'B'">
                <span>Rs. </span>
                <vue-xeditable
                  type="text"
                  name="amount"
                  attribute="rentamount"
                  pattern="^[0-9]"
                  helpingText="Please enter the monthly rent amount"
                  :value.sync="rentamount.amount"
                  :index="index"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  placeholder="Rent Amount"
                  :enable-editing="true"
                  :currentTabId="'amount'+index"
                  :nextTabId="'rentamount'+index"
                  validateText="isNumeric"
                  :amountFormat="true"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{rentamount.amount}}</span>
                <span> per month from the </span>
                <!-- <vue-xeditable
                  type="select"
                  name="fromMonth"
                  attribute="rentamount"
                  placeholder="From Month"
                  pattern="^[0-9]{1,2}$"
                  :value="rentamount.fromMonth"
                  :options="toMonthOptions"
                  :index="index"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :enable-editing="true"
                  :currentTabId="'rentamount'+index"
                  :nextTabId="'toMonth'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="(rentamount.fromMonth=='1' ? readOnly:!readOnly) && !readOnly"
                /> -->
                <span class="vue-xeditable-value">{{rentamount.fromMonth}}</span>
                <sup v-if="rentamount.fromMonth %10 == 1 && rentamount.fromMonth!=11">st </sup>
                <sup v-else-if="rentamount.fromMonth %10== 2 && rentamount.fromMonth!=12">nd </sup>
                <sup v-else-if="rentamount.fromMonth %10== 3 && rentamount.fromMonth!=13">rd</sup>
                <sup v-else>th</sup>
                <span> month to </span>
                <!-- <vue-xeditable
                  type="select"
                  name="toMonth"
                  attribute="rentamount"
                  placeholder="To Month"
                  pattern="^[0-9]{1,2}$"
                  :value="rentamount.toMonth"
                  :options="toMonthOptions"
                  :index="index"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :enable-editing="true"
                  :currentTabId="'toMonth'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /> -->
                <span class="vue-xeditable-value">{{rentamount.toMonth}}</span>
                <sup v-if="rentamount.toMonth %10 == 1 && rentamount.toMonth!=11">st</sup>
                <sup v-else-if="rentamount.toMonth %10 == 2 && rentamount.toMonth!=12">nd </sup>
                <sup v-else-if="rentamount.toMonth %10 == 3 && rentamount.toMonth!=13">rd </sup>
                <sup v-else>th</sup>

                <span> month</span>
                <!-- <span class="addNewBox" v-if="index>0 && !readOnly">
                  <span @click="removeRentBreak(index)">
                    <em class="icon-delete"></em>
                  </span>
                </span> -->
              </li>
              <!-- <div class="text-center pt-15 pb-15" v-if="!readOnly">
                <button @click="incByAmount" class="addNew">
                  <em class="icon-add"></em> More
                </button>
              </div> -->
            </ol>
        <!-- <div class="txt text-center">SCHEDULE I</div> -->
      </div>
    </div>

    <div class="pt-15 pb-15 text-center" v-if="!readOnly">
      <button class="btn btn-primary" @click="finalSubmit">Submit</button>
    </div>


    <overlay :openModel="openModel"></overlay>
  </div>
</template>

<script>
import agreementMixins from "./agreementMixins";

export default {
  name: "MumbaiLeaseAgreement",
  mixins: [agreementMixins],
  data() {
    return {
      reqData: {
        clause: [
          {
            clauses:
              "<strong>Electricity Charges:</strong> The licensee herein shall pay the electricity bills directly for energy consumed on the licensed premises and should submit original receipts to licensor indicating that the electricity bills are paid. ",
          },
          {
            clauses:
              "<strong>Alteration:</strong> That the Licensee shall not make or permit to do any alteration or addition to the construction or arrangements (internal or external) to the Licensed premises without previous consent in writing from the Licensor",
          },
          {
            clauses:
              "<strong>No Tenancy:</strong> That the Licensee shall not claim any tenancy right and shall not have any right to transfer, assign, and sublet or grant any license or sub-license in respect of the Licensed Premises or any part thereof and also shall not mortgage or raise any loan against the said premises.",
          },
          {
            clauses:
              "<strong>Inspection:</strong> That, the Licensor shall on reasonable notice given by the Licensor to the Licensee shall have a right of access either by himself or through authorized representative to enter, view and inspect the Licensed premises at reasonable intervals.",
          },
          {
            clauses:
              "<strong>Possession:</strong> That immediately upon the expiration or termination or cancellation of this agreement the Licensee shall vacate the said premises without delay with all his/her goods and belongings. In the event of the Licensee failing and / or neglecting to remove himself and / or his/her articles from the said premises on expiry or sooner determination of this Agreement, the Licensor shall be entitled to recover damages at the rate of double the daily amount of compensation per day and or alternatively the Licensor shall be entitled to remove the Licensee and his/her belongings from the Licensed premises, without recourse to the Court of Law. ",
          },
          {
            clauses:
              "<strong>Furniture and Appliances:</strong> The said premises is having the Furniture and Appliances mentioned in the Schedule II. The licensee shall maintain the said Furniture and Appliances in the said premises in its existing condition and damage, if any, caused to the said Furniture and Appliances, the same shall be repaired by the Licensee/s at its own cost subject to normal wear and tear. ",
          },
          // {
          //   "clauses":"This Agreement shall be governed by and interpreted in accordance with the laws of India. All disputes, differences, disagreements, controversies or claims arising out of or in connection with this Agreement, including the validity, effect, and interpretation thereof, shall, at the request of either party, be referred to the sole arbitrator mutually appointed by both the parties, who shall conduct the arbitration proceedings in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, or any amendment or statutory modification or replacement/substitution thereof Any award made by the arbitrator shall be final and binding on the Parties. The cost and expenses of the arbitration proceedings, including fees of the arbitrators, shall be borne equally by the Parties. The venue of arbitration shall be as mutually} decided by the parties."
          // },
          // {
          //   "clauses":"<strong>Force Majeure</strong>: If the whole or any part of the Licensed Premises shall at any time during the term	of the lease be destroyed or damaged due to any force majeure circumstances including fire,	storm, tempest, flood, Act of God, an act of terrorism, war or any other irresistible force or the	Licensee is deprived of the use of the Licensed Premises for reasons not attributable to the Licensee, 	the Licensor hereby undertakes to restore the said Premises as expeditiously as possible or, as 	the case may be, to remove the impediment in its use and occupation as expeditiously as	possible. Notwithstanding the foregoing, upon the happening of any such event as aforesaid, 	the Licensee shall not be liable to pay License Fees during the period the Licensee is deprived of 	the use of the said Premises or any part thereof. The Licensee shall also have the option to terminate the Lease after the event by giving one month's notice and without payment of any rent in lieu thereof and without incurring any liability to pay any other amount whatsoever to the Licensor."
          // },
          // {
          //   "clauses":"<strong>Notice</strong>: Any notice or communication to be addressed by one party to the other shall be in writing and shall be served at the addresses as given hereinabove by registered post with AID or at such other addresses as may be notified in writing by one party to another. Any change in such address shall be promptly notified to the other party in writing."
          // }
        ],
      },
    };
  },
};
</script>

<style>
/* #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 90%;
    padding: 15px 25px;
    margin: 60px auto;
    position: relative;
    display: block;
  } */

.node {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 40px;
}

.events-banner {
  padding: 5px;
  font-size: small;
  display: block;
}

.events-banner .prefix {
  color: #858585;
}

.events-banner .content {
  font-weight: bold;
  color: #273080;
}

.events-banner .value {
  color: #3a5831;
  font-size: larger;
}

.events-banner-event {
  background-color: #f9f9f9;
  margin: 2px;
  padding: 10px;
  display: inline-block;
  clear: left;
  width: auto;
}
.vue-xeditable.enabled {
  position: relative;
  display: contents;
}

h1 {
  margin: 0;
  text-align: center;
  color: #42b983;
}

h4 {
  margin-bottom: 0;
  color: #404040;
}

a {
  color: #07c;
}

pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}

.highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre code,
pre tt {
  background-color: transparent;
  border: none;
}
</style>