<template>
  <div v-if="isIdle">
  </div>
</template>

<script>

export default {
  computed: {
    isIdle: function () {
      if(this.$store.state.idleVue.isIdle){
        sessionStorage.removeItem("token");
        this.logoutUser();
        return true;
      }else{
        return false;
      }
    },
  },
  methods: {
    logoutUser(){
      this.$router.go(this.$router.currentRoute)
    }
  },
};
</script>
