<template>
    <div class="pdfOpen">
        <iframe :src="docUrl" width="100%" height="100%" frameBorder="0"></iframe>
    </div>
</template>
<style>
.pdfOpen{width: 100%;height: 100vh;overflow: hidden;}
.pdfOpen{width: 100%;height: 100vh;overflow: hidden;}
</style>
<script>
import { mapActions } from "vuex";

export default {
    data(){
        return{
            docUrl:""
        }
    },
    async beforeMount(){
        const urlPath = await this.showDocumentPath({"docPath":this.$route.params.name});
        if(urlPath.status){
            this.docUrl = urlPath.url;
            console.log(this.docUrl)
        }
    },
    methods:{
...mapActions('agreement',["showDocumentPath"]),
    }
}
</script>