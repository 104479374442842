<template>
<div class="container">
  <div class="row">
    <div class="aggrementBox">
      <div class="sumbdrBox">
        <div v-if="showIframe" class="modelpdfView">
           <iframe :src="PDFurl" width="100%" height="100%" frameBorder="0" ></iframe>               
        </div>
        <div v-else class="sumBody waterMark usSelectNone" ref="document">
            <component :is="componentName" :readOnly="true" :currentCity="agreementCity" :esignData="previewData"  :stamp="true" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
// import axios from 'axios';
import {mapActions, } from "vuex";
import {defaultView, MumbaiLeaseAgreement,LeaseAgreement} from './templates';

export default {
    name:'agreementById',
    data(){
        return{
            lanlord:'',
            componentName: "defaultView",
            agreementCity:'',
            previewData:"",
            agreementName:'',
            PDFurl:"",
            showIframe:false
        }
    },
    components:{
        MumbaiLeaseAgreement,defaultView,LeaseAgreement
    },
    beforeCreate: function() {
        document.body.className = 'bg-white';
    },
   methods:{
       ...mapActions('agreement',["previewAgreementInfo","showDocumentPath"]),
    async getPreviewData(){
        var data = await this.previewAgreementInfo({"id":this.$route.params.id,"type":this.$route.params.type});
        this.previewData=data;
          let agrType=this.$route.params.cityType;
          if(!agrType){
          if(data.docPath!=undefined && data.docPath!=null){
                this.showIframe=true;
                this.PDFurl=data.docPath;
            }
          }
        // console.log(agrType);
        if(data.status){
        let pckgename=data.data.package.replace(/ /g,"");
        this.agreementCity = data.data.city;
        this.agreementName = data.data.package;
        if(pckgename =='RentalAgreement'  || pckgename == 'RentalAgreement+PoliceVerification'){
            if( this.agreementCity == 'Mumbai' || this.agreementCity == 'Pune'|| this.agreementCity =="Pune Rural"|| this.agreementCity == "Mumbai Rural" || this.agreementCity == 'Thane' ||this.agreementCity == 'Navi Mumbai')
              this.componentName = 'MumbaiLeaseAgreement';
            else
               this.componentName = 'LeaseAgreement';               
          }else{
            this.componentName = 'defaultView';

          }
        }
     },  
    //  async showDocument(url){
    //       url = url.split('/')
    //       this.documentPath = true;
    //       const urlPath = await this.showDocumentPath({"docPath":url[url.length-1]});
    //       console.log(urlPath);
    //       if(urlPath.status){
    //           this.PDFurl = urlPath.url;
    //           console.log(this.PDFurl)
    //       }
    //   },
   
   },
   beforeMount(){
    // this.getUnits();
    this.getPreviewData();
 },
    
}
</script>
