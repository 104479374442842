import { getVendors, assignVendor} from "@/_services/vendors.service";

const actions = {
    async getVendors({ dispatch}, data) {
        try {
            var response = await getVendors(data);
            if (response.data && response.data["status"]) {
                return { "status": true, "data": response.data["vendors"] };
            } else {
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;

            dispatch('alert/error', message, { root: true });
        }
    },
    async assignVendor({ dispatch }, data) {
        try {
            var response = await assignVendor(data);
            return {"status":true,"data":response.data["data"] }
            
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', message, { root: true });
        }
    }
}
export const vendor = {
    namespaced: true,
    actions,
}