import { paymentService, } from "@/_services/payment.service"
import { router } from '../../_helpers/router';

const actions = {
    async createInvoice({ dispatch }, data) {
        try {
            const response = await paymentService.createInvoice(data);
            if (response.data && response.data['status'] == true)
                window.location.replace(response.data['data'].paymentURL);
            else {
                dispatch('alert/error', response.data.message, { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async paymentStatus({ dispatch }, paymentRefNo) {
        try {
            const response = await paymentService.paymentStatus(paymentRefNo);
            if (response.data && !response.data.paymentStatus) {
                router.push('/rentalagreement/' + sessionStorage.getItem("source") + '/' + sessionStorage.getItem("token"));
            } else {
                //dispatch('alert/error',  response.data.message, { root: true });
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async paymentStatus1({ dispatch }, paymentRefNo) {
        try {
            const response = await paymentService.paymentStatus(paymentRefNo);
            if (response.data && response.data.paymentStatus) {
                return true;
            } else {
                return false;
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },

    // async updatePaymentCreator({ dispatch},data) {
    //     try {
    //         console.log(data);
    //         const response = await paymentService.updatePaymentCreator(data);
    //         console.log(response);
    //         if (response.data && response.data['status'] == true) {
    //             return { "status": true,};
    //         }
    //         else {
    //             dispatch('alert/error', );
    //             return {"status":false}
    //         }

    //     } catch (er) {
    //         console.error(er);
    //         let message = er.message;
    //         if (!message)
    //             message = er
    //         dispatch('alert/error', message, { root: true });
    //     }
    // },
}

export const payment = {
    namespaced: true,
    actions
}