<template>
    <div class="container-fluid">
        <div class="transaction">
            <div class="row" v-if="agreementInfos.length >0|| statusType=='complete'">
                <div class="col-lg-12 col-sm-12 col-md-12">
                    <h2 class="esigntabHeading pt-30 pb-15"><a class="btn-bdr fl-right" href="javascript:void(0)" @click="createAgreemtn">Create Agreement <span><em class="icon-add"></em></span></a>My Agreement</h2>
                    <div class="btn-group myAgreementBtn pb-15 pt-15">
                        <a class="btn" href="javascript:void(0)" @click="userActive = 1;currentFilter = 'all';getAgreements(1) "  :class="{active:userActive == 1}">All</a>
                        <a class="btn" href="javascript:void(0)" @click="userActive = 2;currentFilter = 'Completed';getAgreements(2)" :class="{active:userActive == 2}">Completed</a>
                        <a class="btn" href="javascript:void(0)" @click="userActive = 3;currentFilter = 'Incomplete';getAgreements(3)"  :class="{active:userActive == 3}">Incomplete</a>
    <!--                        
                        <input type="text" name="agreementid"  placeholder="Agreement-id" v-model="agreementId" >{{agreementId}}
                         <a class="btn" href="javascript:void(0)" @click="filterAgreement"  >Filter</a>
     -->
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-md-12">
                    <div class="table-responsive">
                    <table cellspacing="0" class="table  table-bordered e-sign">
                        <thead>
                        <tr>
                            <th>S.No</th>
                            <th width="150">Agreement ID</th>
                            <th>Creation Date</th>
                            <th>City</th>
                            <th>Status</th>
                            <th>Agreement Type</th>
                            <th>Role</th>
                            <th>Payment Id & Date</th>
                            <th class="action">Action</th>
    
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(agrinfo,indx) in showAgreements" :key="indx">
                                
                                <td>{{indx+1}}</td>
                                <td>
                                    <a href="javascript:void(0)" v-if="agrinfo.Status == 'Submitted' || agrinfo.Status == 'Paid' || agrinfo.Status == 'BioMetric Scheduled'"  @click="getPreviewData(agrinfo.UniqueId)">{{agrinfo.UniqueId}}</a>
                                    <a href="javascript:void(0)" v-else-if="agrinfo.Status == 'Agreement Generated' && agrinfo.DocPath !==null" @click="showDocument(agrinfo.DocPath)">{{agrinfo.UniqueId}}</a>
                                     <a href="javascript:void(0)" v-else-if="agrinfo.Status == 'Esign Requested'" @click="showEsignData(agrinfo.UniqueId)">{{agrinfo.UniqueId}}</a>
                                    <a href="javascript:void(0)" v-else >{{agrinfo.UniqueId}}</a>
                                </td>
                                <td>{{giveDate(agrinfo.createdAt)}}</td>
                                <td>{{agrinfo.AgreementCityServiceXref.location.Name}}</td>
                                <td>
                                    <span v-if="agrinfo.Status=='Paid' && agrinfo.SubscriptionInfo!==null && agrinfo.Data.city!=='Mumbai' && agrinfo.Data.city!=='Pune' && agrinfo.Data.city!=='Thane' && agrinfo.Data.city!=='Navi Mumbai'" >
                                     {{'Free Agreement'}}
                                    </span>
                                    <span v-else-if="agrinfo.Status==='Paid' && agrinfo.SubscriptionInfo!==null &&(agrinfo.Data.city=='Mumbai' || agrinfo.Data.city=='Pune' || agrinfo.Data.city=='Thane' || agrinfo.Data.city=='Navi Mumbai')">
                                        {{'Paid (only stamp duty)' }}
                                    </span>
                                    <span v-else>
                                        {{agrinfo.Status}}
                                    </span>
                                </td>
                                <td>{{agrinfo.AgreementCityServiceXref.ServiceZref.RefName}}</td>
                                <td>{{agrinfo.Role}}</td>
                                <td>
                                    <span v-if="agrinfo.PaymentDetail != null">
                                        {{agrinfo.PaymentDetail.txnRefNo}}<br>
                                        {{giveDate(agrinfo.PaymentDetail.txnDate)}}   
                                    </span>
                                    <span v-else-if="agrinfo.SubscriptionInfo!==null">Complimentary Agreement</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span class="myagreement addNewBox">
                                        <span class="downloadIcon tooltip iconCircle"  @click="exportToPDF(agrinfo.encryptedId,agrinfo.PaymentDetail), pdfDownload=true" v-if="agrinfo.Status !='Draft' && agrinfo.DocPath == null && (agrinfo.Status == 'Submitted'  ||agrinfo.Status == 'Paid' ||agrinfo.Status == 'Esign Requested' )&& agrinfo.Role !== 'Referrer'">
                                            <em class="icon-download"></em>
                                            <span class="tooltiptext">Download Agreement</span>
                                        </span>
                                        <span v-else-if="agrinfo.DocPath !== null&& agrinfo.Role !== 'Referrer'" class="downloadIcon tooltip iconCircle">
                                            <a :href="agrinfo.DocPath" target="_blank">
                                                <em class="icon-download"></em>
                                            </a>
                                            <span class="tooltiptext">Download Agreement</span>
                                        </span>
                                        <span v-if="agrinfo.policeVerificationDoc !== null && agrinfo.Role !== 'Referrer'" class="downloadIcon tooltip iconCircle">
                                            <a :href="agrinfo.policeVerificationDoc" target="_blank">
                                                <em class="icon-download"></em>
                                            </a>
                                            <span class="tooltiptext">Download Police Verification</span>
                                        </span>
                                        <span class="edit tooltip iconCircle" @click="editAgreement(agrinfo.encryptedId)" v-if="(agrinfo.PaymentDetail == null && agrinfo.SubscriptionInfo==null || agrinfo.Status == 'Submitted' ) && agrinfo.Role !== 'Referrer'" >
                                            <em class="icon-edit"></em>
                                            <span class="tooltiptext">Edit</span>
                                        </span>
                                        <!-- <span class="pdfView" @click="getPreviewData(agrinfo.UniqueId)"><em class="icon-view"></em></span> -->
                                        <span @click="payFree(agrinfo.encryptedId)" v-if="((agrinfo.PaymentDetail == null && agrinfo.SubscriptionInfo==null) || (agrinfo.PaymentUrl && agrinfo.PaymentDetail == null))&& agrinfo.Status != 'Draft'&& agrinfo.Role !== 'Referrer' " class="payIcon tooltip iconCircle">
                                            <em class="icon-credit-card"></em>
                                            <span class="tooltiptext">Pay Now</span>
                                        </span>
                                        <span @click="actionbtn(agrinfo.encryptedId,agrinfo.Status,agrinfo.AgreementCityServiceXref.location.Name)" v-if="(agrinfo.AgreementCityServiceXref.location.Name != 'Mumbai' && agrinfo.AgreementCityServiceXref.location.Name != 'Pune' && agrinfo.AgreementCityServiceXref.location.Name != 'Thane' && agrinfo.AgreementCityServiceXref.location.Name != 'Navi Mumbai' )  && agrinfo.Status == 'Paid'&& agrinfo.Role !== 'Referrer' " class="actionIcon tooltip iconCircle">
                                            <em class="icon-aim"></em>
                                            <span class="tooltiptext">Initiate E-sign</span>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-lg-12 col-sm-12 col-md-12">
                    <h2 class="esigntabHeading pt-30 pb-15"><a class="btn-bdr fl-right" href="javascript:void(0)" @click="createAgreemtn">Create Agreement <span><em class="icon-add"></em></span></a>My Agreement</h2>
                </div>
                <div class="col-lg-7 col-sm-12 col-md-7 text-center mx-auto pt-15 pb-15 clearBoth">
                    <img src="./../../assets/images/history.png" class="img-responsive" />
                    <h2 class="pt-15 esigntabHeading">No Agreements to show yet</h2>
                    <p class="pt-15 pb-15">Want to create an agreement for your property in a hassle free manner? Create agreement digitally without any physical intervention.</p>
                    <button class="btn btn-primary" @click="createAgreemtn">Create Agreement now</button>
                </div>
            </div>
        </div>
        <div class="row" v-show="showPaginate">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <paginate
                    v-model="page"
                    :page-count="pageCount"
                    :margin-pages="2"
                    :page-range="5"
                    :click-handler="paginatePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :page-link-class="'page-link-item'"
                    :prev-class="'prev-item'"
                    :prev-link-class="'prev-link-item'"
                    :next-class="'next-item'"
                    :next-link-class="'next-link-item'"
                    :break-view-class="'break-view'"
                    :break-view-link-class="'break-view-link'"
                    :first-last-button="false"
                >
                </paginate>
            </div>
        </div>
        <div class="modelOverlay" :class="{active : modelPrivew == true || documentPath == true || esigndetailPopup==true}" modelPrivew></div>
        <div class="agrmentPrivewModel" v-if="modelPrivew == true">
            <div class="container">
                <div class="row">
                    <div class="aggrementBox" :class=" {'agrementPDF':pdfDownload?true:false}">
                        <div class="sumbdrBox">
                            <button @click="closePrivew" class="closePrivew"><em class="icon-close"></em></button>
                            <div ref="document">
                                <div  class="sumBody  usSelectNone" :class="{'waterMark': paymentStatus?true:false, 'pdfDownload':pdfDownload?true:false}">
                                    <component :is="componentName" :readOnly="true" :currentCity="agreementCity" :esignData="previewData"  :stamp="true" :pdfDownload="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <overlay :openModel="openModel"></overlay>
        <div class="agrmentPrivewModel" v-if="documentPath == true">
            <div class="container pdfContainer">
                <div class="row">
                    <div class="aggrementBox">
                        <div class="sumbdrBox">
                            <div class="modelpdfView">
                                <button @click="closePDFview" class="closePrivew"><em class="icon-close"></em></button>
                                <iframe :src="PDFurl" width="100%" height="100%" frameBorder="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          <div class="agrmentPrivewModel" v-if="esigndetailPopup==true">
            <div class="container">
                <div class="row">
                    <div class="aggrementBox">
                        <div class="sumbdrBox">
                            <div class="iframeView">
                                <button @click="closeEsignPopup" class="closePrivew"><em class="icon-close"></em></button>
                              <iframe :src="iframeUrl" width="100%" height="100%" frameBorder="0" ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
import { getItemFromStorage } from "@/_helpers/utility"
import {  mapActions } from "vuex";
import {defaultView, MumbaiLeaseAgreement,LeaseAgreement} from './templates';
// import html2pdf from 'html2pdf.js'
import Paginate from 'vuejs-paginate';
import overlay from "./templates/overlay";


export default {
    name:'mayAgreement',
    
    data(){
        return{
            agreementInfos:[],
            pageLimit:10,
            page:0,
            pageCount:0,
            userActive: 3,
            currentFilter:'Incomplete',
            componentName: "defaultView",
            agreementCity:'',
            previewData:"",
            agreementName:"",
            modelPrivew:"",
            pdfDownload:false,
            documentPath:"",
            PDFurl:"",
            paymentStatus:true,
            agreementId:'',
            openModel: "",
            showPaginate:false,
            statusType:'',
            iframeUrl:'',
            esigndetailPopup:false
        }
    },
    components:{
        MumbaiLeaseAgreement,defaultView,LeaseAgreement,Paginate,overlay
    },
    beforeCreate: function() {
        document.body.className = 'bg-white';
    },
    async beforeMount(){
        await this.getUserAgreements();
        await this.documentHeight();
    },
    methods:{
        ...mapActions("agreement",["getUserInfoByNumber","previewAgreementInfo","showDocumentPath","downloadPdf"]),
        async getUserAgreements(page,limit,status){
             const pageNo = page!=null?page:this.page;
             const limitNo = limit!=null?limit:this.pageLimit;
            if("user" in sessionStorage){
                const data = getItemFromStorage("user");
                // console.log(data.UserName);
                this.openModel = "loader";
                const response = await this.getUserInfoByNumber({"page":pageNo,"limit":limitNo,"userName":data.UserName,"status":status});
                this.pageCount=Math.ceil(response.count/limitNo);
                if(response.count>10){
                    this.showPaginate=true
                }else{
                    this.showPaginate=false
                }
                this.openModel = "";
                //  console.log(response.count)
                if(response.status){
                    this.agreementInfos = response.data;
                    // console.log(this.agreementInfos);

                }else{
                    this.agreementInfos = [];
                }
            }
        },

        async getPreviewData(id){
            var data = await this.previewAgreementInfo({"id":id,"type":2});
            this.previewData=data;
            this.modelPrivew = true;
            let customClause = [];
            for (var c = 0; c < this.previewData.data.clause.length; c++) {
                if (this.previewData.data.clause[c].custom) {
                    customClause.push(this.previewData.data.clause[c]);
                    this.previewData.data.clause.splice(c, 1);
                    c=c-1;
                }
            }
            this.previewData.data.customClause = customClause;
            if(data.status){
                let pckgename=data.data.package.replace(/ /g,"");
                this.agreementCity = data.data.city;
                this.agreementName = data.data.package;
                if(pckgename=='RentalAgreement'  || pckgename == 'RentalAgreement+PoliceVerification'){
                    if(this.agreementCity == 'Mumbai' || this.agreementCity == 'Pune' || this.agreementCity == 'Thane' || this.agreementCity == 'Navi Mumbai' || this.agreementCity == "Pune Rural" ||  this.agreementCity == "Mumbai Rural")
                        this.componentName = "MumbaiLeaseAgreement"
                    else
                        this.componentName = 'LeaseAgreement'  
                    }else{
                        this.componentName = 'defaultView';
                }
            }
            for(let i=0;i<this.previewData.data.witness.length;i++){
          if(this.previewData.data.witness[i].witnessName==''||this.previewData.data.witness[i].witnessPhone==''){
           this.previewData.data.witness[i].visible=false
          }
             }
        },
        showEsignData(id){
            //  console.log(id)
              this.esigndetailPopup=true
            const url=process.env.VUE_APP_IFRAME_URL+'/editAgreementParties/'+this.$route.params.source+'/'+this.$route.params.token+'/'+'2'+'/'+id
            this.iframeUrl=url
        },
         paginatePage(pageNum){
              pageNum = pageNum-1;
              this.page=pageNum
            if(this.statusType=='complete'){
                this.getUserAgreements(this.page,this.pageLimit,'complete');
            }else if(this.statusType=='incomplete'){
                 this.getUserAgreements(this.page,this.pageLimit,'incomplete');
            }else if(this.statusType=='all'){
                this.getUserAgreements(this.page,this.pageLimit);
            }
            else{
               this.getUserAgreements(this.page,this.pageLimit);
            }
            this.page=pageNum+1;
          },
        async showDocument(url){
            url = url.split('/')
            this.documentPath = true;
            // console.log(url);
            const urlPath = await this.showDocumentPath({"docPath":url[url.length-1]});
            // console.log(urlPath);
            if(urlPath.status){
                this.PDFurl = urlPath.url;
                //console.log(this.PDFurl)
            }
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async exportToPDF (id,pymnt) {
            this.paymentStatus = true;
            if(pymnt){
                this.paymentStatus = false;
            }
            this.openModel = "loader";
            const filename=`Agreement_${new Date().getTime()}.pdf`;
            const response = await this.downloadPdf({
                filename,
                "margin": {
                    "top": 50,
                    "right": 5,
                    "bottom": 80,
                    "left": 5
                },
                "url":process.env.VUE_APP_PDF_URL+id+'/1',
                "printBackground" : this.paymentStatus
            });
            const data=response['data'].data;
            const linkSource = `data:application/pdf;base64,${data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = filename;
            downloadLink.click();
            this.openModel = "";
        },
        closePrivew(){
            this.modelPrivew = false;
        },
        closePDFview(){
            this.documentPath = false;
        },
        userFilterKey(){
            return this[this.userFilterKey]
        },
        closeEsignPopup(){
           this.esigndetailPopup=false
        },
        giveDate(data){
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const newMonth = months[date.getMonth()];
            const day = date.getDate();
            const year = date.getFullYear();
            return day + " " + newMonth + " " + year;
        },
        editAgreement(id){
            window.parent.postMessage({type:'edit',id:id}, '*');
            //window.location.replace('/rentalagreement/'+this.$route.params.source+'/'+ this.$route.params.token+'/'+id)
        },
        payFree(id){
            window.parent.postMessage({type:'payment', id:id}, '*');
           // console.log('/agreementView/'+id)
            //window.location.replace('/agreementView/'+id);
        },
        createAgreemtn(){
            sessionStorage.clear();
            window.parent.postMessage({type:'create'}, '*');
            //window.location.replace('/rentalagreement/'+this.$route.params.source+'/'+ this.$route.params.token);
            //this.window.location();
        },
        // filterAgreement(){
        //    this.agreementInfos.filter(id=>this.agreementInfos.UniquiId=id);
        // },
        actionbtn(id,status,city){
            //console.log(status,city);
            if(status == "Paid"){
                if(city !='Mumbai' && city !='Pune' && city !='Thane' && city !='Navi Mumbai' ){
                    window.parent.postMessage({type:'paid',id:id}, '*');
                    //window.location.replace('/eSign/'+ id);
                }
            }
        },
        documentHeight(){
            var body = document.body;
            var html = document.documentElement; 
            var bodyH = Math.max(body.scrollHeight, body.offsetHeight, body.getBoundingClientRect().height, html.clientHeight, html.scrollHeight, html.offsetHeight);
           // window.parent.postMessage({height:bodyH+'px'}, '*');
            window.parent.postMessage({height:bodyH+'px'}, '*');
        },
        getAgreements(statusType){
            // this.showPaginate=false
            if(statusType===2){
                this.statusType='complete'
                 this.paginatePage(1);  
            }else if(statusType===3){
                this.statusType='incomplete'
                this.paginatePage(1);  
            }else{
                 this.statusType='all'
                 this.paginatePage(1);  
            }
        }
    },
    computed: {
        showAgreements() {
            return this[this.currentFilter]
        },
        all() {
        return this.agreementInfos
        },
        Completed() {
            return this.agreementInfos.filter((x) => x.Status == 'Agreement Generated')
        },
        Incomplete() {
            return this.agreementInfos.filter((x) => x.Status != 'Agreement Generated')
        }
  }, 
}
</script>