const getItemFromStorage=function(keyName){
    if (keyName in sessionStorage){
        const str=sessionStorage.getItem(keyName);
        try {
            const result = JSON.parse(str);
            return result;
        } catch (e) {
            return str;
        }
    }
    else
         return {};
}

const setItemInStorage=function(keyName,str){
    const type = Object.prototype.toString.call(str);
    if(type=='[object Object]' || type == '[object Array]')
    sessionStorage.setItem(keyName, JSON.stringify(str));
    else
    sessionStorage.setItem(keyName, str);
}

export {
    getItemFromStorage,
    setItemInStorage
}