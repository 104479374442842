import { checkCoupon, getCoupons } from "@/_services/coupon.service"
import { checkCPlusDiscount ,submitSubAgreement} from "../../_services/coupon.service";

const state = {
    data: {}
}


const actions = {
    async checkCoupon({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await checkCoupon(data);
            if (response.data && response.data["status"]) {
                // dispatch('alert/success', "Agreement Party Details Updated successfully", { root: true });
                return response.data; 
            } else {
                commit('AgreementFailure', data.id);
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false };
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },

    async getCoupons({ commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await getCoupons(data);
            return response.data; 
        } catch (er) {
            let message = er.message;
            if (!message)
                message = er;
           return {status:false};
        }
    },

    async checkCplusDiscount({ dispatch, }, data) {
        try {
            var response = await checkCPlusDiscount(data);
            return response
            
        } catch (error) {
            console.error(error);
            let message = error.message;
            dispatch('alert/error', message, { root: true });
        }
    },
    async submitSubscriptionAgreement({ dispatch, }, data) {
        try {
            var response = await submitSubAgreement(data);
            return response
            
        } catch (error) {
            console.error(error);
            let message = error.message;
            dispatch('alert/error', message, { root: true });
        }
    }


}

const mutations = {
    AgreementRequest(state) {
        state.status = { requesting: true };
    },
    AgreementSuccess(state, Id) {
        state.status = { TransactionId: Id };
    },
    AgreementFailure(state, Id) {
        state.status = { requesting: false, TransactionId: Id };
        if (Id && Id !== '' && Id !== null) {
            state.status.TransactionId = Id;
        }
    },
}

export const coupon = {
    namespaced: true,
    state,
    // getters,
    actions,
    mutations
}