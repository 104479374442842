<template>
    <div class="modelOverlay" :style="{'display':openModel.length>0?'block':'none'}" v-if="openModel.length>0">
        <div class="loader" v-if="openModel == 'loader'">
            <img src="../../../assets/images/loader.gif" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props:["openModel"]
}
</script>