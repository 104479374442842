<template>
  <div id="app">
    <div class="transparentOverlay"></div>
    <div v-if="alert.message" :class="`alert active ${alert.type}`">{{alert.message}} <span @click="closeAlert"><em class="icon-close" ></em></span></div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState,mapGetters, mapActions } from "vuex";

export default {
  name: 'App',
  computed: {
      ...mapGetters('master', ['getData']),
      ...mapState({
            alert: state => state.alert
        })
  },
  methods: {
        ...mapActions('master', ['fetchData']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),

        closeAlert(){
          document.getElementsByClassName('alert')[0].classList.remove('active');
        }

        
  },
  watch: {
        $route (){
            // clear alert on location change
            this.clearAlert();
        }
    },
  async created(){      
      // Make network request if the data is empty
      if (!this.getData || !this.getData.Cities) 
        await this.fetchData();
 }
}
</script>
