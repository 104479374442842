import Vue from 'vue'
import App from './App.vue'
import { router } from './_helpers';
import store from './_store';
import IdleVue from "idle-vue";
import VeeValidate from 'vee-validate'
import './assets/lib/css/bootstrap.min.css'
import './assets/lib/css/moonicon.css'
import './assets/css/payment.css'

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    store,
    idleTime: 60 * 60 * 1000, // 3 seconds
    startAtIdle: false
});

new Vue({
    router: router,
    store,
    render: h => h(App),
}).$mount('#app')